import { numberPercentage, numberText } from "../helpers/helpers";
import { COUNT_VARIABLE_PREFIX } from "../helpers/config";
import LoadingIcon from "../loadingIcon";

export default function DataTable({
  filteredData,
  demo_header,
  years,
  voteCountYears,
  totalTurnout,
  loading,
}) {
  let columnHeadings = [];
  columnHeadings.push(...years);
  return (
    <div className="position-relative scroll-x w-100" tabIndex="0">
      {loading && (
        <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
          <LoadingIcon />
        </div>
      )}
      <table className="table mb-0" style={{ opacity: loading ? ".2" : "1" }}>
        <thead className="bg-dark text-white">
          <tr>
            <td>{demo_header}</td>
            {columnHeadings.map((heading, i) => {
              return <td key={"heading" + i}>{heading}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data) => {
            return (
              <tr key={data.key}>
                <th>{data.key}</th>
                {years.map((year, i) => {
                  let year_count_variable =
                    voteCountYears[COUNT_VARIABLE_PREFIX + i];
                  let raw_count = data.value[year_count_variable];
                  return (
                    <td data-label={year} key={"year" + i}>
                      {numberText(raw_count)}{" "}
                      {numberPercentage(
                        raw_count,
                        totalTurnout[year_count_variable]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot className="bg-gray-300">
          <tr>
            <th>Total</th>
            {years.map((y, i) => {
              let year_count_variable =
                voteCountYears[COUNT_VARIABLE_PREFIX + i];
              return (
                <td key={"year_count" + i}>
                  {numberText(totalTurnout[year_count_variable])}
                </td>
              );
            })}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
