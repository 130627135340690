import {
  age,
  createGroupCountFields,
  gender,
  GEO_TYPE_OPTIONS,
  GEO_TYPE_OPTIONS_MAPPING,
  maritalStatus,
  modeledParty,
  race,
  urbanicity,
  voterScore,
  voteSimilarPriorElection,
  voteSimilarPriorElection_2,
} from "../../helpers/config";

const TITLE = "GA 2022 Senate Runoff";
const DATA_FOLDER = "r2022";
const FINAL_EAV_COUNT_PREFIX = "final_eav_voted_count_";
const CURRENT_EAV_COUNT_PREFIX = "current_eav_voted_count_";
const FINAL_EDAY_COUNT_PREFIX = "final_eday_voted_count_";
const TS_MODELED_PARTY_COUNT_FIELD = "count_cy";
const COUNT_PREFIXES = {
  FINAL_EAV_COUNT_PREFIX: FINAL_EAV_COUNT_PREFIX,
  CURRENT_EAV_COUNT_PREFIX: CURRENT_EAV_COUNT_PREFIX,
  FINAL_EDAY_COUNT_PREFIX: FINAL_EDAY_COUNT_PREFIX,
  TS_COUNT_PREFIX: "count_",
};
const YEARS = ["cyminus3", "cyminus2", "cyminus1", "cy"];
const YEAR_LABELS = ["G2018", "R2021", "G2022", "R2022"];

const YEAR_MAPPING = Object.assign(
  ...YEARS.map((k, i) => ({ [k]: YEAR_LABELS[i] }))
);
let COUNT_FIELDS = createGroupCountFields(YEARS, COUNT_PREFIXES);

export const DEMOGRAPHIC_MAPPING = {
  modeledParty: "party_score_rollup",
  voterScore: "voterbase_voter_score",
  age: "age_bucket",
  race: "race",
  gender: "voterbase_gender",
  urbanicity: "urbanicity",
  maritalStatus: "marital_status",
  voteSimilarPriorElection: "vote_r2021",
  voteSimilarPriorElection_2: "vote_g2018",
};

const DEMOGRAPHIC_OPTIONS = [
  { label: "Modeled Party", value: modeledParty },
  { label: "Vote Frequency", value: voterScore },
  { label: "Age", value: age },
  { label: "Race", value: race },
  { label: "Gender", value: gender },
  { label: "Marital Status", value: maritalStatus },
  { label: "Urbanicity", value: urbanicity },
  { label: "Voted in 2021", value: voteSimilarPriorElection },
  { label: "Voted in 2018", value: voteSimilarPriorElection_2 },
];

const YEAR_COLORS = ["#457b9d", "#cfdeef", "#1e3556", "#446296"];
let config = {
  TITLE: TITLE,
  states: [{ label: "Georgia", value: "GA" }],
  DEMO_OPTIONS: DEMOGRAPHIC_OPTIONS,
  DEMOGRAPHIC_MAPPING: DEMOGRAPHIC_MAPPING,
  YEARS: YEARS,
  YEAR_COLORS: YEAR_COLORS,
  COUNT_FIELDS: COUNT_FIELDS,
  COUNT_PREFIXES: COUNT_PREFIXES,
  GEO_TYPE_OPTIONS_MAPPING: GEO_TYPE_OPTIONS_MAPPING,
  GEO_TYPE_OPTIONS: GEO_TYPE_OPTIONS,
  TS_MODELED_PARTY_COUNT_FIELD: TS_MODELED_PARTY_COUNT_FIELD,
  DATA_FOLDER: DATA_FOLDER,
  YEAR_LABELS: YEAR_LABELS,
  YEAR_MAPPING: YEAR_MAPPING,
  isCurrent: false,
};
export { config };
