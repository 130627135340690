import { Link } from "react-router-dom";
import Card from "../card";
import CardImg1 from "../../img/bars4.png";
import CardImg2 from "../../img/bars1.png";
import CardImg3 from "../../img/bulb.png";
import TargetSmartLogoWordMark from "../../targetsmart_design/bootstrap-theme/dist/imgs/logo-word-mark.svg";
import { setDocumentTitle } from "../helpers/helpers";

export default function Home() {
  setDocumentTitle();

  return (
    <div className="d-flex flex-column justify-content-around align-items-center w-100 py-5 px-md-3 flex-wrap">
      <div className="px-5 text-center">
        <h1>Welcome to TargetEarly</h1>
        <p className="small text-muted font-weight-light">
          Explore{" "}
          <img
            src={TargetSmartLogoWordMark}
            style={{ height: "1em", marginRight: "1px", marginBottom: "-1px" }}
            className="d-inline"
            aria-hidden={true}
            alt="targetsmart logo"
          />
          <span className="sr-only">TargetSmart</span>’s authoritative, near
          real-time stream of early and absentee vote data for recent and
          ongoing elections.
        </p>
      </div>
      <div
        className="d-flex flex-md-row flex-column justify-content-around align-items-center w-100 py-5 px-md-3 flex-wrap"
        style={{ maxWidth: "100em" }}
      >
        <Link to="/g2023" className="m-3 election-card">
          <Card className="text-dark d-flex justify-content-between align-items-center pb-3 pt-4 pl-5 h-100 shadow-sm hover-scale-up">
            <div>
              <div className="h3">2023 General</div>
              <p>
                Compare Early and Absentee vote data in the 2021, 2022, and 2023 General elections.
              </p>
            </div>
            <img src={CardImg2} alt="" width="80px" className="ml-2" />
          </Card>
        </Link>
        <Link to="/r2022" className="m-3 election-card">
          <Card className="text-dark d-flex justify-content-between align-items-center pb-3 pt-4 pl-5 h-100 shadow-sm hover-scale-up">
            <div>
              <div className="h3">GA 2022 Runoff</div>
              <p>
                Compare Early and Absentee vote data in the 2018, 2021 Runoff,
                2022, and 2022 Runoff Georgia elections.
              </p>
            </div>
            <img src={CardImg1} alt="" width="80px" className="ml-2" />
          </Card>
        </Link>
        <Link to="/g2022" className="m-3 election-card">
          <Card className="text-dark d-flex justify-content-between align-items-center pb-3 pt-4 pl-5 h-100 shadow-sm hover-scale-up">
            <div>
              <div className="h3">US 2022 Midterms</div>
              <p>
                Compare Early and Absentee vote data in the 2018, 2020, and 2022
                elections.
              </p>
            </div>
            <img src={CardImg2} alt="" width="80px" className="ml-2" />
          </Card>
        </Link>
        <Link to="/g2021" className="m-3 election-card">
          <Card className="text-dark d-flex justify-content-between align-items-center pb-3 pt-4 pl-5 h-100 shadow-sm hover-scale-up">
            <div>
              <div className="h3">NJ & VA 2021</div>
              <p>
                Compare Early and Absentee vote data in the 2017, 2019, 2020,
                and 2021 elections.
              </p>
            </div>
            <img src={CardImg1} alt="" width="80px" className="ml-2" />
          </Card>
        </Link>
        <a
          href="https://targetsmart.com/insights-newsletters/"
          className="m-3 election-card"
        >
          <Card className="text-dark d-flex justify-content-between align-items-center pb-3 pt-4 pl-5 h-100 shadow-sm hover-scale-up">
            <div>
              <div className="h3">TargetSmart Insights</div>
              <p>
                Read TargetSmart custom insights on our Early and Absentee vote
                data.
              </p>
            </div>
            <img src={CardImg3} alt="" width="80px" className="ml-2" />
          </Card>
        </a>
      </div>
    </div>
  );
}
