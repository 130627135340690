import { useContext, useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { drawBarChart } from "../helpers/barChartHelpers";
import { ToolTip } from "../toolTip";

import { JsonParam, useQueryParams, withDefault } from "use-query-params";

import { ConfigContext } from "../ConfigContext";
import ChartError from "./ChartError";

export default function HorizontalBar({
  height,
  width,
  data,
  view_type,
  state,
  error,
}) {
  let [query] = useQueryParams({
    demo_filters: withDefault(JsonParam, [
      { key: "modeledParty", value: "All" },
    ]),
  });

  let { demo_filters } = query;
  let lastDemoFilter = demo_filters[demo_filters.length - 1].key;
  const config = useContext(ConfigContext);
  const svgRef = useRef(null);

  let [isDataEmpty, setIsDataEmpty] = useState(false);

  let [toolTipData, setToolTipData] = useState({
    show: false,
    top: 0,
    left: 0,
    year: "",
    voteCount: "",
    demoKey: "",
  });

  const setToolTip = (newData) => {
    let tooltip = Object.assign(toolTipData, newData);
    setToolTipData({ ...tooltip });
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    setIsDataEmpty(checkBarDataEmpty(data));
    drawBarChart(
      svg,
      data,
      view_type,
      state,
      setToolTip,
      config.YEAR_LABELS,
      config.YEAR_COLORS,
      config.YEAR_MAPPING
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let demoLabel = config.DEMO_OPTIONS.find((d) => {
    return d.value === lastDemoFilter;
  })?.label;
  return (
    <>
      <svg
        ref={svgRef}
        viewBox={"0 0 " + width + " " + height}
        style={{ display: !isDataEmpty && !error ? "inherit" : "none" }}
        aria-hidden={true}
      />

      {isDataEmpty && !error && (
        <ChartError
          error="There is no data matching your current filters."
          height={height}
          width={width}
        />
      )}
      {!!error && <ChartError error={error} height={height} width={width} />}
      <ToolTip
        show={toolTipData.show}
        left={toolTipData.left - 110}
        top={toolTipData.top - window.scrollY + 50}
        width={220}
      >
        <div className=" d-flex flex-column mb-0">
          <div className="d-flex align-items-center bg-gray-100 p-2 rounded-top border-bottom">
            {toolTipData.year}
          </div>
        </div>
        <div className="pt-2 p-2 bg-white rounded-bottom">
          <div>
            {demoLabel}:{" "}
            <span className="font-weight-bold">{toolTipData.demoKey}</span>
          </div>
          <div>
            Count:{" "}
            <span className="font-weight-bold">{toolTipData.voteCount} </span>
            <span className="small">({toolTipData.percent})</span>
          </div>
        </div>
      </ToolTip>
    </>
  );
}

function checkBarDataEmpty(data) {
  if (data.length === 0) return true;
  let empty = true;
  for (let i = 0; i < data.length; i++) {
    if (data[i].vote_count && data[i].total_turnout) empty = false;
  }
  return empty;
}
