import {
  JsonParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { modeledParty, STATE_OPTIONS } from "./helpers/config";

export default function Disclaimers({
  showNoRegPartyWarning,
  explicit_state,
  editorialNotes,
}) {
  let state_default = !!explicit_state
    ? explicit_state
    : STATE_OPTIONS[0].value;

  let showNoVhAvailWarning = false;
  let [query] = useQueryParams({
    view_type: withDefault(StringParam, "National"),
    state: withDefault(StringParam, state_default),
    demo_filters: withDefault(JsonParam, [
      { key: "modeledParty", value: "All" },
    ]),
    geo_type: StringParam,
  });
  let { view_type, state, demo_filters, geo_type } = query;

  let modeledPartyActive = false;
  demo_filters.forEach((filter) => {
    if (filter.key === modeledParty) modeledPartyActive = true;
  });

  if (view_type === "state" && editorialNotes) {
    let stateNotes = editorialNotes.filter((item) => {
      return item.State === state;
    });
    let vh_avail = stateNotes[0]["FinalVHReported"];
    if (vh_avail !== "Y") {
      showNoVhAvailWarning = true;
    }
  }

  return (
    <div className="small w-100 mb-4">
      {showNoRegPartyWarning && (
        <div className="mb-2 text-danger">
          <span className="fa fa-warning mr-2"></span>
          {state} does not have partisan voter registration. To view modeled
          party counts for {state}, please select Modeled Party.
        </div>
      )}
      {showNoVhAvailWarning && (
        <div className="mb-2 text-danger">
          <span className="fa fa-warning mr-2"></span>
          Post-election vote history is not yet available in {state}.
        </div>
      )}
      {modeledPartyActive && (
        <>
          <div className="mb-2">
            <span className="fa fa-info-circle text-info mr-2"></span> Modeled
            Party represents a partisanship prediction derived from internal
            TargetSmart{" "}
            <a target="_blank" href="/modeling">
              modeling
            </a>
            .
          </div>
          <div className="mb-2">
            <span className="fa fa-warning text-warning mr-2"></span>
            Partisanship predictions are not intended to predict specific votes
            cast or election outcomes.
          </div>
        </>
      )}
      {geo_type === "dmas" && (
        <div>
          <span className="fa fa-info-circle text-secondary mr-2"></span>
          The Media Market geo type only displays vote counts within the state
          selected, even if the selected market covers multiple states.
        </div>
      )}
    </div>
  );
}
