import {
  age,
  createGroupCountFields,
  gender,
  genderMaritalStatus,
  GEO_TYPE_OPTIONS,
  GEO_TYPE_OPTIONS_MAPPING,
  modeledParty,
  raceEdu,
  registeredParty,
  urbanicity,
  voterScore,
  voteSimilarPriorElection,
} from "../../helpers/config";

const TITLE = "GA 2021 Senate Runoff";
const DATA_FOLDER = "r2021";
const FINAL_EAV_COUNT_PREFIX = "Final_eav_voted_count_";
const CURRENT_EAV_COUNT_PREFIX = "Total_voted_count_";
const FINAL_EDAY_COUNT_PREFIX = "Final_eday_voted_count_";
const TS_MODELED_PARTY_COUNT_FIELD = "Total_voted_count_2021";
const COUNT_PREFIXES = {
  FINAL_EAV_COUNT_PREFIX: FINAL_EAV_COUNT_PREFIX,
  CURRENT_EAV_COUNT_PREFIX: CURRENT_EAV_COUNT_PREFIX,
  FINAL_EDAY_COUNT_PREFIX: FINAL_EDAY_COUNT_PREFIX,
  TS_COUNT_PREFIX: CURRENT_EAV_COUNT_PREFIX,
};
const YEARS = ["2016", "2018", "2020", "2021"];
//Could be done better. Need a mapping for newer configs so want to stay consistent
const YEAR_MAPPING = Object.assign(...YEARS.map((k, i) => ({ [k]: YEARS[i] })));
let COUNT_FIELDS = createGroupCountFields(YEARS, COUNT_PREFIXES);

//Demographic field mappings for R2021 and G2020!!OFFICAL SOURCE !! USE THESE!!!
export const DEMOGRAPHIC_MAPPING = {
  modeledParty: "party_score_rollup",
  registeredParty: "party_rollup",
  voterScore: "voterbase_voter_score",
  age: "age_bucket",
  race: "race_rollup",
  gender: "voterbase_gender",
  urbanicity: "Urbanicity",
  raceEdu: "Race_Edu",
  genderMaritalStatus: "gender_and_marital_status",
  voteSimilarPriorElection: "vote_similar_prior_election",
};

const DEMOGRAPHIC_OPTIONS = [
  { label: "Modeled Party", value: modeledParty },
  { label: "Vote Frequency", value: voterScore },
  { label: "Registered Party", value: registeredParty },
  { label: "Age", value: age },
  { label: "Gender", value: gender },
  { label: "Urbanicity", value: urbanicity },
  { label: "Race + Education", value: raceEdu },
  { label: "Gender + Marital Status", value: genderMaritalStatus },
  { label: "Voted in 2016", value: voteSimilarPriorElection },
];

const YEAR_COLORS = ["#457b9d", "#cfdeef", "#1e3556", "#446296"];
let config = {
  TITLE: TITLE,
  states: [{ label: "Georgia", value: "GA" }],
  DEMO_OPTIONS: DEMOGRAPHIC_OPTIONS,
  DEMOGRAPHIC_MAPPING: DEMOGRAPHIC_MAPPING,
  YEARS: YEARS,
  YEAR_COLORS: YEAR_COLORS,
  COUNT_FIELDS: COUNT_FIELDS,
  COUNT_PREFIXES: COUNT_PREFIXES,
  GEO_TYPE_OPTIONS_MAPPING: GEO_TYPE_OPTIONS_MAPPING,
  GEO_TYPE_OPTIONS: GEO_TYPE_OPTIONS,
  TS_MODELED_PARTY_COUNT_FIELD: TS_MODELED_PARTY_COUNT_FIELD,
  DATA_FOLDER: DATA_FOLDER,
  YEAR_LABELS: YEARS,
  YEAR_MAPPING: YEAR_MAPPING,
};
export { config };
