export default function RadioButton({
  buttonLabel,
  buttonValue,
  buttonPrefix,
  countPrefix,
  setCountPrefix,
}) {
  // Single component for radio buttons
  // buttonPrefix prefix for given button vs
  // countPrefix is current selected prefix
  return (
    <div className="pr-2 ">
      <label
        className="form-check-label font-weight-normal"
        htmlFor={buttonValue}
      >
        <input
          className="mr-1"
          type="radio"
          value={buttonValue}
          checked={countPrefix === buttonPrefix}
          onChange={(e) => {
            setCountPrefix(buttonPrefix);
          }}
        />
        {buttonLabel}
      </label>
    </div>
  );
}
