import { Link } from "react-router-dom";
import { ReactComponent as TargetSmartLogo } from "../targetsmart_design/bootstrap-theme/dist/imgs/logo.svg";
import { ReactComponent as TargetEarlyLogo } from "../img/targetearly-logo.svg";

export default function Header() {
  return (
    <div className="w-100 p-4 d-flex justify-content-between bg-white align-items-center">
      <div className="d-flex flex-md-row flex-column align-items-center">
        <Link to="/">
          <TargetEarlyLogo
            height="24px"
            width="140px"
            className="w-auto mr-4"
            alt="TargetEarly Logo"
          />
          <span className="sr-only">TargetEarly Home</span>
        </Link>
        <div className="mt-md-0 mt-3 font-weight-normal">
          <Link to="/about" className="mr-3">
            About
          </Link>
          <a
            className="mr-3"
            href="https://targetsmart.com/insights-newsletters/"
            target="_blank"
            rel="noreferrer"
            title="TargetSmart's tip sheet provides regular updates on the latest voter registration, early vote, and voter turnout trends."
          >
            Insights
          </a>
        </div>
      </div>
      <div className="d-flex align-items-md-center justify-content-end flex-md-row flex-column">
        <TargetSmartLogo
          height="20px"
          width="110px"
          className="w-auto mr-1"
          alt="TargetSmart Logo"
        />
        <div className="mt-md-0 mt-3 d-flex flex-row justify-content-end">
          <a
            className="ml-3"
            href="http://www.twitter.com/targetsmart"
            target="blank"
            rel="noreferrer"
            title="TargetSmart on Twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            className="ml-3"
            href="https://www.facebook.com/TargetSmart-160418807408255/?fref=ts"
            target="blank"
            title="TargetSmart on Facebook"
          >
            <i className="fab fa-facebook"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
