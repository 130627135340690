import { useQueryParams, StringParam, JsonParam } from "use-query-params";
import { encodeQueryParams } from "serialize-query-params";
import { stringify } from "query-string";

import { Redirect } from "react-router-dom";

import { URL_PARAM_LABELS } from "../components/helpers/config";

export default function RedirectOldUrls() {
  return (
    <Redirect
      to={{
        pathname: getRedirectBase(),
        search: useConvertedParamString(),
      }}
    />
  );
}

function useConvertedParamString() {
  let [query] = useQueryParams({
    view_type: StringParam,
    demo: StringParam,
    demo_val: StringParam,
    demo2: StringParam,
    geo_type: StringParam,
    cd: StringParam,
    state: StringParam,
  });
  let { view_type, state, demo, demo_val, demo2, geo_type, cd } = query;

  let demo_filters;

  if (!!demo) {
    demo_filters = [
      {
        key: filterDemo(demo),
        value: demo_val ? filterDemoValue(demo_val) : "All",
      },
    ];
    if (!!demo2) demo_filters.push({ key: filterDemo(demo2), value: "All" });
  } else {
  }

  view_type = filterViewType(view_type);
  const encodedQuery = encodeQueryParams(
    {
      view_type: StringParam,
      state: StringParam,
      geo_type: StringParam,
      geo: StringParam,
      demo_filters: JsonParam,
    },
    { view_type, state, geo_type, geo: cd, demo_filters }
  );
  return stringify(encodedQuery);
}

function getRedirectBase() {
  let redirMatches = window.location.href.match(/([a-zA-Z])*.html/);
  if (redirMatches && redirMatches[0] === "historic.html") {
    return "g2020/";
  }
  if (redirMatches && redirMatches[0] === "current.html") {
    return "r2021/";
  }
  return "";
}

function filterDemo(demo) {
  let demoLabel = demo;
  Object.keys(URL_PARAM_LABELS).forEach((key) => {
    if (URL_PARAM_LABELS[key] === demoLabel) demoLabel = key;
  });
  if (demoLabel === "Race   Education") demoLabel = "raceEdu";
  if (demoLabel === "Gender   Marital Status")
    demoLabel = "genderMaritalStatus";
  return demoLabel;
}

function filterDemoValue(demoVal) {
  let demoValLabel = demoVal;
  if (demoValLabel === "Race   Education") demoValLabel = "raceEdu";
  if (demoValLabel === "Gender   Marital Status")
    demoValLabel = "genderMaritalStatus";
  return demoValLabel;
}

function filterViewType(vt) {
  let viewType = vt;
  if (viewType === "State") viewType = "state";
  return viewType;
}
