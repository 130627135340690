export default function LoadingIcon({ aspectRatio, className, label }) {
  return (
    <div
      className={
        "d-flex align-items-center justify-content-center w-100 position-relative text-gray-600 " +
          className || ""
      }
      style={{
        paddingTop: aspectRatio * 100 + "%",
      }}
    >
      <i
        style={{ top: "50%", marginTop: "-.5em" }}
        className="fas fa-spinner fa-spin fa-3x position-absolute"
      ></i>
      <span
        style={{ top: "50%", marginTop: "3em" }}
        className="position-absolute small"
      >
        {label ? label : "Loading..."}
      </span>
    </div>
  );
}
