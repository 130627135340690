import * as d3 from "d3";
import { decompressSync } from 'fflate';
import { getDemoFilePath, getTSFilePath, HOSTNAME } from "./helpers";


export function getTimeSeriesData(geo, dataFolder, onProgress) {
  let tsFilePath = getTSFilePath(geo, dataFolder);
  let f = abortableFetch(tsFilePath);

  return {
    promise: new Promise((resolve, reject) => {
      f.promise.then((res) => {
        if (res.status !== 200) {
          reject(res);
        }
        readBodyWithProgress(res, onProgress)
          .then((d) => {
            try {
              let text = new TextDecoder("utf-8").decode(d);
              if (text[0] === "<") {
                reject(res);
                return;
              }
              let data = d3.csvParse(text);
              resolve(data);
            } catch (error) {
              reject(error);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }),
    abort: f.abort,
  };
}

export function getEditorialNotes(dataFolder) {
  return fetch(HOSTNAME + `/data/${dataFolder}/demos/editorial_notes.csv`).then(
    (res) => {
      if (res.status === 200) {
        return res.text().then((notes) => {
          return d3.csvParse(notes);
        });
      } else {
        return null;
      }
    }
  );
}

export function fetchDemoData(fileName, geoType, dataFolder, onProgress) {
  let filePath = getDemoFilePath(fileName, geoType, dataFolder);
  let f = abortableFetch(filePath);

  return {
    promise: new Promise((resolve, reject) => {
      f.promise
        .then(async (res) => {
          if (res.status !== 200) {
            reject(res);
            return;
          }
          let modTime = res.headers.get("Last-Modified");
          readBodyWithProgress(res, onProgress).then((data) => {
            try {
              let d = d3.csvParse(
                new TextDecoder("utf-8").decode(decompressSync(new Uint8Array(data)))
              );
              resolve({
                data: d,
                modTime: new Intl.DateTimeFormat("en-US", {
                  timeStyle: "full",
                  dateStyle: "medium",
                }).format(new Date(modTime)),
              });
            } catch (error) {
              reject(error);
            }
          });
        })
        .catch((e) => {
          reject(e);
        });
    }),
    abort: f.abort,
  };
}

function abortableFetch(request, opts) {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    promise: fetch(request, { ...opts, signal }),
  };
}

function readBodyWithProgress(res, onProgress) {
  return new Promise(async (resolve, reject) => {
    let reader = res.body.getReader();
    const contentLength = +res.headers.get("Content-Length");
    let receivedLength = 0;
    let chunks = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      onProgress(receivedLength / contentLength);
    }
    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }
    resolve(chunksAll);
  });
}
