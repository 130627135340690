import * as d3 from "d3";
import { numberText, wrap } from "./helpers";
import { getYAxisRange } from "./timeSeriesHelpers";
import {
  drawTargetSmartLogo,
  getColorScale,
  getTsHeaderLead,
  width,
  height,
} from "./graphHelpers";

// // var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
var margin = { top: 20, right: 20, bottom: 70, left: 50, group: 30 };
var legend_width = 110;
var legend_height = 30;

// // === DRAW DEMOGRAPHIC BAR CHART AND DATA TABLE ====
export function drawBarChart(
  svg,
  calculatedData,
  view_type,
  state,
  setToolTip,
  years,
  yearColors,
  yearMapping
) {
  var color_scale = getColorScale(years, yearColors);

  let space = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

  let y_scale = getYAxisRange(calculatedData);

  let formatPercent = d3.format(".0%");
  let formatPercentTens = d3.format(".01%");

  let uniqueGroups = [];
  let uniqueYears = [];
  calculatedData.forEach((d) => {
    if (!uniqueGroups.includes(d.group)) uniqueGroups.push(d.group);
    if (!uniqueYears.includes(d.year)) uniqueYears.push(d.year);
  });
  let numCategories = uniqueGroups.length;
  let numYears = uniqueYears.length;
  let group_width =
    (width - margin.right - margin.left - legend_width) / numCategories;
  let column_padding = 5;
  let column_width =
    (group_width - 2 * column_padding - margin.group) / numYears;

  // Add gridlines
  let gridlines = d3
    .axisLeft()
    .tickFormat("")
    .tickSize(-(width - margin.right - margin.left - legend_width))
    .scale(y_scale);

  space
    .append("g")
    .attr("class", "axis")
    .call(gridlines)
    .call(function (g) {
      g.selectAll(".tick")
        .selectAll("line")
        .attr("class", "dashed stroke-gray-500");
      return g.selectAll(".domain").remove();
    });

  let legend = space
    .selectAll(".legend")
    .data(color_scale.domain().reverse())
    .enter()
    .append("g");

  legend
    .append("rect")
    .attr("class", "legend")
    .attr("width", 20)
    .attr("height", 20)
    .attr("y", function (d, i) {
      legend_height = color_scale.domain().length * 25;
      let temp = height / 2 - legend_height / 2;
      return temp - i * 25;
    })
    .attr("x", width - legend_width - margin.right)
    .style("fill", function (d) {
      return color_scale(d);
    });

  legend
    .append("text")
    .attr("dy", "1em")
    .attr("y", function (d, i) {
      legend_height = color_scale.domain().length * 25;
      let temp = height / 2 - legend_height / 2;
      return temp - i * 25;
    })
    .attr("x", width - legend_width - margin.right + 25)
    .style("fill", "black")
    .attr("z-index", 1000)
    .text(function (d) {
      return "- " + d;
    });

  var bar = space.selectAll(".bar").data(calculatedData);

  bar
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("x", function (d) {
      return (
        margin.group / 2 +
        d.group * group_width +
        d.index * (column_padding + column_width)
      );
    })
    .attr("y", function (d) {
      return y_scale(d.value);
    })
    .attr("height", function (d) {
      return height - margin.bottom - y_scale(d["value"]);
    })
    .attr("width", column_width)
    .style("fill", function (d, i) {
      return color_scale(d.year);
    })
    .on("mouseover", (e, d) => {
      setToolTip({
        show: true,
        year: yearMapping[d.year],
        voteCount: numberText(d.vote_count),
        percent: formatPercentTens(d.value),
        demoKey: d.key,
      });
    })
    .on("mouseout", () => {
      setToolTip({ show: false });
    })
    .on("mousemove", (e) => {
      setToolTip({
        top: e.pageY,
        left: e.pageX,
      });
    });

  // Construct x_scale
  let uniqueCategories = [];
  calculatedData.forEach((d) => {
    if (!uniqueCategories.includes(d.key)) uniqueCategories.push(d.key);
  });

  let x_scale = d3
    .scaleBand()
    .domain(uniqueCategories)
    .range([0, width - margin.right - margin.left - legend_width]);

  // Add the x Axis
  let x_axis = d3
    .axisBottom()
    .scale(x_scale)
    .ticks(numCategories + 1);

  space
    .append("g")
    .attr("transform", "translate(0, " + (height - margin.bottom) + ")")
    .attr(
      "class",
      "text-gray-800 " +
        (uniqueCategories.length > 7 ? "font-size-5" : "font-size-6")
    )
    .call(x_axis)
    .call(function (g) {
      return g.select(".domain").remove();
    })
    .selectAll(".tick text")
    .call(wrap, group_width);

  // Add y Axis

  let y_axis = d3
    .axisLeft()
    .scale(y_scale)
    .tickFormat(formatPercent)
    .ticks(y_scale.domain()[0] / 0.05);

  space
    .append("g")
    .attr("class", "text-gray-800")
    .call(y_axis)
    .call(function (g) {
      return g.select(".domain").remove();
    });

  // Add X and Y labels
  space
    .append("text")
    .attr("fill", "#6c757d")
    .attr("y", height - margin.bottom + margin.group + 4)
    .attr("x", width / 2 - 70)
    .attr("dy", ".5em")
    // .attr("font-size", "8px")
    .attr("class", "font-size-md-10 font-size-lg-6 font-weight-normal")
    .style("text-anchor", "middle")
    .text("Demographic");

  space
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 0 - margin.left + 3)
    .attr("x", 0 - height / 2 + 15)
    .attr("dy", ".5em")
    .attr("fill", "#6c757d")
    .attr("class", "font-size-md-10 font-size-lg-6 font-weight-normal")
    .style("text-anchor", "middle")
    .text(getTsHeaderLead(view_type, state) + ": % share of ballots cast");

  // Add bar labels
  space
    .append("g")
    .selectAll(".textlabel")
    .data(calculatedData)
    .enter()
    .append("text")
    .attr(
      "class",
      "text-center text-anchor-middle text-gray-800 " +
        (uniqueCategories.length > 7 ? "font-size-3" : "x-small")
    )
    .attr("fill", "#6c757d")
    .attr("x", function (d) {
      return (
        margin.group / 2 +
        d.group * group_width +
        d.index * (column_padding + column_width) +
        column_width / 2
      );
    })
    .attr("y", function (d) {
      return y_scale(d.value);
    })
    .attr("dy", "-.25em")
    .text(function (d) {
      return formatPercentTens(d.value);
    });

  drawTargetSmartLogo(space);
}
