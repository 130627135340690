import { useEffect, useState } from "react";
import { sendEvent } from "./analytics";

export default function ShareButton({ title }) {
  let [expanded, setExpanded] = useState(false);
  let [copied, setCopied] = useState(false);
  useEffect(() => {
    if (expanded === true) {
      let anyClick = (e) => {
        setExpanded(false);
      };
      window.addEventListener("click", anyClick, false);
      return () => {
        window.removeEventListener("click", anyClick, false);
      };
    }
  }, [expanded]);

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <div className="position-relative ml-4">
      <button
        className={
          "py-2 px-3 btn-primary border-0 " +
          (expanded ? "rounded-top" : "rounded")
        }
        style={{ minWidth: "8em" }}
        onClick={() => {
          sendEvent("Share Button", "Clicked Share Button");
          setExpanded(!expanded);
        }}
        aria-expanded={expanded}
      >
        Share Custom{" "}
        <span className="text-nowrap">
          URL <span className="fa fa-share ml-2"></span>
        </span>
      </button>
      {copied && (
        <div className="position-absolute left-0 w-100 d-flex justify-content-center align-items-center">
          <div
            className="mt-3 shaddow-sm p-2 bg-white rounded font-size-7 bubble-arrow-above-before"
            role="alert"
          >
            URL Copied!
          </div>
        </div>
      )}
      {expanded && (
        <div
          className="bg-dark text-white rounded-bottom py-2 px-3 w-100 position-absolute"
          style={{ zIndex: 20 }}
        >
          <button
            className="border-0 py-1 px-0 bg-transparent text-white w-100 text-left font-weight-light"
            onClick={() => {
              if (!navigator.clipboard) {
                // fallbackCopyTextToClipboard(text, el, success);
                return;
              }
              navigator.clipboard.writeText(window.location).then(
                function () {
                  setCopied(true);
                },
                function (err) {
                  alert("failed to copy");
                }
              );
            }}
          >
            <span className="fas fa-copy mr-2"></span>Copy URL
          </button>
          <a
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              window.location
            )}&text=${encodeURIComponent(title)}`}
            target="_blank"
            rel="noreferrer"
            className="text-white py-1 px-0 w-100 d-block"
          >
            <span className="fab fa-twitter mr-2"></span>Twitter
          </a>
          <a
            href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              window.location
            )}`}
            target="_blank"
            rel="noreferrer"
            className="text-white py-1 px-0 w-100 d-block"
          >
            <span className="fab fa-facebook mr-2"></span>Facebook
          </a>
          <a
            href={`mailto:?subject=${encodeURIComponent(
              title
            )}&body=${encodeURIComponent(window.location)}`}
            target="_blank"
            rel="noreferrer"
            className="text-white py-1 px-0 w-100 d-block"
          >
            <span className="fas fa-envelope mr-2"></span>Email
          </a>
        </div>
      )}
    </div>
  );
}
