import * as d3 from "d3";
import useExpanded from "../hooks/useExpanded";
import ModalWrapper from "../modalWrapper";

let dateFormat = d3.timeFormat("%m/%d");

export default function TimeSeriesTable({ title, data, headers, dataKeys }) {
  let [expanded, setExpanded] = useExpanded();

  return (
    <>
      <button
        aria-hidden={true}
        onClick={() => {
          setExpanded(true);
        }}
        className="border-0 btn bg-gray-200 text-gray-800 btn-sm"
      >
        View data table
      </button>
      <ModalWrapper className={!expanded ? "sr-only" : ""}>
        <div className="h-100 w-100 overflow-auto">
          {title && (
            <div className="h3 mb-2" aria-hidden={true}>
              {title}
            </div>
          )}
          <table className="table" tabIndex="0">
            {headers && (
              <thead className="bg-dark text-white">
                <tr>
                  {headers.map((h) => {
                    return <td key={"header_" + h}>{h}</td>;
                  })}
                </tr>
              </thead>
            )}
            {data && (
              <tbody>
                {data.map((d, i) => {
                  return (
                    <tr key={d.key.toString()}>
                      <td>
                        {d.key instanceof Date ? dateFormat(d.key) : d.key}
                      </td>
                      {dataKeys &&
                        dataKeys.map((key) => {
                          return <td key={key + i}> {d.value[key]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <button
          className="position-absolute top-0 right-0 border-0 bg-gray-200 m-4 py-1 px-3"
          onClick={() => {
            setExpanded(false);
          }}
        >
          <span className="fa fa-close"></span>
          <span className="sr-only">close full screen graph</span>
        </button>
      </ModalWrapper>
    </>
  );
}
