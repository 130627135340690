import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { init, sendPageview } from "../analytics";
import usePagePercentage from "./usePagePercentage";

export default function useGoogleAnalytics() {
  const location = useLocation();
  usePagePercentage();
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);
}
