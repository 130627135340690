export default function SalesBanner() {
  let bannerMessages = [
    <>
      {" "}
      👋 Strategists, TargetSmart can help you exclude known early voters from
      your GOTV universe.{" "}
      <a
        href="https://targetsmart.com/#hpcontactus"
        target="_blank"
        className="text-info"
        rel="noreferrer"
      >
        Contact Us
      </a>
    </>,
    <>
      {" "}
      👋 Last minute digital buys?{" "}
      <a
        href="https://targetsmart.com/#hpcontactus"
        target="_blank"
        className="text-info"
        rel="noreferrer"
      >
        We can help reach applicants
      </a>{" "}
      or exclude those who have returned their ballots!
    </>,
    <>
      {" "}
      💡{" "}
      <a
        href="https://targetsmart.com/insights-newsletters/"
        target="_blank"
        className="text-info"
        rel="noreferrer"
      >
        TargetSmart Insights:
      </a>{" "}
      TargetSmart's tip sheet provides regular updates on the latest voter
      registration, early vote, and voter turnout trends.
    </>,
    <>
      {" "}
      📞 30 million wireless numbers were added to our voter file. Contact us
      about{" "}
      <a
        href="https://targetsmart.com/cellbase"
        target="_blank"
        className="text-info"
        rel="noreferrer"
      >
        CellBase
      </a>
      !
    </>,
  ];
  let bannerMessage =
    bannerMessages[Math.floor(Math.random() * bannerMessages.length)];

  return (
    <div className="bg-dark text-white w-100 text-center">{bannerMessage}</div>
  );
}
