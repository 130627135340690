import React from "react";
import { useQueryParam } from "use-query-params";
import Select from "react-select";
import { DROPDOWN_STYLES } from "./styles";

export default function BaseDropdown({ items, label, paramName, paramType }) {
  const [param, setParam] = useQueryParam(paramName, paramType);

  const onChange = (e) => {
    setParam(e.value);
  };

  let selectedItem = items.find((item) => {
    return item.value === param;
  });

  return (
    <div className="d-flex flex-column">
      <label id={label + "_select_label"} className="text-nowrap">
        {label}:
      </label>
      <Select
        aria-labelledby={label + "_select_label"}
        styles={DROPDOWN_STYLES}
        options={items}
        value={selectedItem}
        placeholder={`Select a ${label?.toLowerCase()}`}
        onChange={onChange}
        defaultValue={items[0]}
      />
    </div>
  );
}
