import { setDocumentTitle } from "../helpers/helpers";
import PageBand from "../pageBand";
import PageWrapper from "../pageWrapper";

export default function Modeling() {
  setDocumentTitle("Modeling");
  return (
    <PageBand>
      <PageWrapper>
        <div className="row">
          <div className="two-thirds column">
            <h3>Understanding modeled data</h3>
            <p>
              A highlight of TargetEarly is its early and absentee partisan
              affiliation reporting. For some states, TargetSmart is able to
              collect official party registration information for voters. In
              other cases, state governments do not make this information
              available. When official party registration information is not
              available, TargetSmart does the next best thing which is to
              predict the party affiliation of voters. Like any prediction there
              may be inaccuracies. We are careful to label our predictions as{" "}
              <i>"Modeled"</i> so you can understand this is a prediction of
              party affiliation.
            </p>
            <h3 className="bg-warning p-3 h6 text-center rounded w-auto d-inline-block">
              <span className="fa fa-warning mr-2"></span> Party registration
              and predicted party alignment statistics are not intended to
              predict votes or election outcome
            </h3>
            <p>
              Voters don't always vote lock step with their party
              registration/alignment. Please do not mistake TargetSmart's
              prediction of party alignment with election outcome predictions.
            </p>
            <h3>
              How are modeled Democrat and Republican party alignment
              determined?
            </h3>
            <p>
              TargetSmart has developed a proprietary partisanship
              classification model to determine the likely political affiliation
              of a voter. This predictive model is trained using survey data and
              incorporates various data points such as vote history, party
              affiliation (where available), consumer interests, and demographic
              information to make a prediction of how likely a person is to
              align with the Democratic party. Those marked as
              <i>Unaffiliated</i> did not fall into a score range where we could
              make a confident prediction of affiliation.
            </p>
            <p>
              The partisan model was constructed using over 56,000 survey
              respondents, both over the phone and online. Model scores are
              expressed from 0-100, representing the probability that a person
              identifies as a Democrat. The model was used to score over 256
              million voting age persons nationwide.
            </p>
          </div>
        </div>
      </PageWrapper>
    </PageBand>
  );
}
