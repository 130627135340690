import { Link } from "react-router-dom";
import { setDocumentTitle } from "../helpers/helpers";
import PageBand from "../pageBand";
import PageWrapper from "../pageWrapper";

export default function NotFound404() {
  setDocumentTitle("404");

  return (
    <PageWrapper>
      <PageBand className="text-center">
        <span className="fa fa-exclamation-triangle text-gray-600 font-size-50 mt-3 mb-5"></span>
        <h1>Not Found</h1>
        <p>The page you're looking for doesn't exist.</p>
        <p>
          <Link to="/">Return home</Link>
        </p>
      </PageBand>
    </PageWrapper>
  );
}
