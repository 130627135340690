export const DROPDOWN_STYLES = {
  option: (provided, state) => ({
    ...provided,
    padding: ".5em",
  }),
  control: (provided, state) => ({
    display: "flex",
    // padding: ".05em",
    background: "white",
  }),
  //   container: (provided, state) => ({
  //     // ...provided,
  //     // borderRadius: 0,
  //     // border: "none",
  //   }),
  indicatorsContainer: (provided, state) => ({
    width: "2em",
    padding: ".25em",
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: ".25em",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: ".25em",
  }),
  svg: () => ({}),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: ".9em",
  }),
};
