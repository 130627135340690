import { useEffect } from "react";
import { getEditorialNotes } from "../helpers/services";

export default function useEditorialData({ config }) {
  useEffect(() => {
    getEditorialNotes(config.DATA_FOLDER).then((notes) => {
      config.editorialNotes = notes;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
