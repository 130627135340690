import ReactGA from "react-ga4";

const TRACKING_ID = "G-0RHVC14J28";

// Example taken from https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/
export function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, {
    debug_mode: isDev,
    GaOptions: { anonymizeIp: true },
  });
}

export function sendEvent(category, action, label) {
  ReactGA.event({ category: category, action: action, label: label });
}

export function sendPageview(path) {
  ReactGA.set({ page: path });
}
