import PageWrapper from "../pageWrapper";
import PageBand from "../pageBand";
import { setDocumentTitle } from "../helpers/helpers";

export default function Cookies() {
  setDocumentTitle("Cookie and Tracking Policy");

  return (
    <PageBand>
      <PageWrapper>
        <div id="privacy" className="container">
          <div className="row">
            <div>
              <h3>Cookie and Tracking Policy</h3>
              <p>
                TargetSmart Communications ("we", "us", "TargetSmart")
                understands that privacy is important to the data subject
                ("you", "your", "user") and we are committed to being
                transparent about the technologies we use. This Cookie Policy
                explains how and why cookies and other similar technologies may
                be stored on and accessed from your device when you use or visit
                targetearly.targetsmart.com or other sites that post a link to
                this Policy (collectively, "the Sites"). This Cookie Policy
                should be read together with our Privacy Policy.
              </p>

              <h4>How do we use cookies and other tracking technologies?</h4>
              <p>
                We use cookies and/or other tracking technologies to collect and
                store information when you visit the Sites for the following
                purposes:
              </p>

              <ul>
                <li>To identify the geographic source of web requests</li>
                <li>
                  To identify the browser and device type associated with web
                  requests
                </li>
                <li>For analytics and research</li>
                <ul>
                  <li>
                    To measure and analyze the audience for each page in order
                    to subsequently improve the ergonomics, browsing, or
                    visibility of content;
                  </li>
                  <li>
                    To measure the reliability of the Sites by analyzing the
                    number of visits to its pages in real time;
                  </li>
                </ul>
              </ul>

              <p>
                The data collected by the Sites will not be kept for longer than
                is necessary to fulfill the purposes mentioned above.
              </p>
              <p>
                The tracking technologies or cookies used on the Sites include:
              </p>
              <ul>
                <li>
                  <strong>Google Analytics:</strong> Google provides us with
                  analytical information about web requests. Google Analytics
                  sets a cookie in order to evaluate use of our services and
                  compile a report for us.{" "}
                  <a
                    href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#disableCookies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More information.
                  </a>
                </li>
              </ul>

              <h4>Does TargetSmart collect personal data/identify me?</h4>
              <p>
                No, any potential personally identifiable data, such as IP
                address, is made anonymous before being stored or sent to Google
                Analytics.
              </p>
            </div>
          </div>
        </div>
      </PageWrapper>
    </PageBand>
  );
}
