import * as d3 from "d3";
import { useContext, useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";
import { ConfigContext } from "./ConfigContext";
import { battlegroundMapping } from "./helpers/graphHelpers";
import {
  getFullStateName,
  getVoteSharePath,
  numberText,
} from "./helpers/helpers";

export default function VoteShare({
  viewType,
  state,
  filePath,
  yearLabels,
  editorialNotes,
}) {
  let setQuery = useQueryParams({})[1];
  let config = useContext(ConfigContext);
  let [voteShares, setVoteShares] = useState({});
  let [totalVotes, setTotalVotes] = useState({});
  let comparisonYear = yearLabels[0];
  let currentYear = yearLabels[yearLabels.length - 1];
  useEffect(() => {
    let url = getVoteSharePath(filePath);
    fetch(url).then((res) => {
      if (res.status !== 200) return;
      res.text().then((d) => {
        let data = d3.csvParse(d);
        let vs = {};
        let tv = {};
        data.forEach(function (d) {
          vs[d.vf_source_state] = d["voteshare"];
          tv[d.vf_source_state] =
            d[config.VOTESHARE_CURRENT_YEAR_FIELD || "cy_votes"];
        });
        setVoteShares(vs);
        setTotalVotes(tv);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePath]);

  let voteShareContent;
  let voteshare = "";
  let vote_total = totalVotes[state];
  if (viewType === "National") {
    voteshare = voteShares[viewType];
    vote_total = totalVotes[viewType];
    voteShareContent = (
      <>
        In {currentYear}, early voters cast <strong>{voteshare}%</strong> of the
        total votes counted in the {comparisonYear} general election.
        <br />
        At least <strong> {numberText(vote_total)}</strong> voters cast early
        ballots in the {currentYear} general election.
      </>
    );
  } else if (viewType === "state") {
    voteshare = voteShares[state];
    vote_total = totalVotes[state];
    voteShareContent = (
      <>
        In <strong>{getFullStateName(state)}</strong>, early voters cast{" "}
        <strong>{voteshare}%</strong> of the total votes counted statewide in
        the {comparisonYear} election.
        <br />
        At least <strong>{numberText(vote_total)}</strong> voters cast early
        ballots in the {currentYear} election.
      </>
    );
  } else if (
    viewType === "PresBS" ||
    viewType === "SenateBS" ||
    viewType === "GovernorBS"
  ) {
    voteshare = voteShares[viewType];
    vote_total = totalVotes[viewType];
    let bsStates;
    if (!!editorialNotes) {
      if (viewType === "PresBS") {
        bsStates = editorialNotes.filter((state) => {
          return state.PresBattleground === "Y";
        });
      } else if (viewType === "SenateBS") {
        bsStates = editorialNotes.filter((state) => {
          return state.SenateBattleground === "Y";
        });
      } else if (viewType === "GovernorBS") {
        bsStates = editorialNotes.filter((state) => {
          return state.GovernorBattleground === "Y";
        });
      }
    }
    let statesContent = (
      <>
        {bsStates &&
          bsStates.map((s, i) => {
            return (
              <span key={"bsStateLink" + i}>
                <button
                  className="p-0 text-primary display-inline border-0"
                  onClick={() => {
                    setQuery({ state: s.State, view_type: "state" }, "push");
                  }}
                >
                  {s.State_Name}
                </button>
                {i !== bsStates.length - 1 && ", "}
              </span>
            );
          })}
      </>
    );

    voteShareContent = (
      <>
        In <strong>{battlegroundMapping(viewType)}</strong>, early voters cast{" "}
        <strong>{voteshare}%</strong> of the total votes counted in the{" "}
        {comparisonYear} general election.
        <br />
        At least <strong>{numberText(vote_total)}</strong> voters cast early
        ballots in these states in the {currentYear} general election.
        <div className="mt-2">
          <strong>{battlegroundMapping(viewType)}: </strong>
          {statesContent}
        </div>
      </>
    );
  }

  if (voteshare === "") return <></>;
  return <div>{voteShareContent}</div>;
}
