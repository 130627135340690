import { useState } from "react";
import {
  JsonParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { ConfigContext } from "../../ConfigContext";
import Disclaimers from "../../disclaimers";
import DropdownMenu from "../../dropdowns/dropdownMenu";
import ScreenReaderDropDownInstructions from "../../dropdowns/screenReaderDropDownInstructions";
import { STATE_PARAM } from "../../dropdowns/urlParams";
import FullScreenWrapper from "../../fullScreenWrapper";
import DataTable from "../../graphs/dataTable";
import HorizontalBar from "../../graphs/horizontalBar";
import { ModeledPartyRollup } from "../../graphs/modeledPartyRollup";
import { YearlyComparison } from "../../graphs/yearlyComparison";
import { getTsHeaderLead } from "../../helpers/graphHelpers";
import { setDocumentTitle } from "../../helpers/helpers";
import useDemoData from "../../hooks/useDemoData";
import useEditorialData from "../../hooks/useEditorialData";
import useTimeSeries from "../../hooks/useTimeSeries";
import PageBand from "../../pageBand";
import PageWrapper from "../../pageWrapper";
import ProgressBar from "../../progressBar";
import ShareButton from "../../shareButton";
import CompareVoteCountButtons from "../../VoteCountRadioButtons/compareVoteCountButtons";
import VoteShare from "../../voteShare";

import { config } from "./config";

export default function G2022() {
  let [countPrefix, setCountPrefix] = useState(
    config.COUNT_PREFIXES.FINAL_EAV_COUNT_PREFIX
  );

  let voteCountYears = {
    voteCountYear0: countPrefix + config.YEARS[0],
    voteCountYear1: countPrefix + config.YEARS[1],
    voteCountYear2: countPrefix + config.YEARS[2],
  };

  let [query] = useQueryParams({
    geo_type: withDefault(StringParam, "statewide"),
    geo: withDefault(StringParam, "All"),
    demo_filters: withDefault(JsonParam, [
      { key: "modeledParty", value: "All" },
    ]),
    state: withDefault(StringParam, config.states[0].value),
    view_type: withDefault(StringParam, "National"),
  });
  // Keep these snake case to signify they came from URL query parameters
  let { geo_type, geo, demo_filters, view_type, state } = query;

  useEditorialData({ config });

  let {
    demoLoadPercent,
    filteredData,
    totalTurnout,
    calculatedData,
    demoErrorMessage,
    lastModified,
    showNoRegPartyWarning,
    demoHeader,
  } = useDemoData({
    config,
    geo_type,
    geo,
    demo_filters,
    state,
    countPrefix,
    voteCountYears,
    view_type,
  });

  let {
    timeSeriesLoadPercent,
    tsErrorMessage,
    modeledPartyRollup,
    rollupYearComparisons,
  } = useTimeSeries({
    config,
    geo: view_type === STATE_PARAM ? state : view_type,
  });

  let title =
    getTsHeaderLead(view_type, state, true) +
    " " +
    config.TITLE +
    " Early & Absentee Vote Report";

  let modeledPartyTitle =
    getTsHeaderLead(view_type, state, true) +
    " Early & Absentee Votes Cast - By Modeled Party";
  let YearlyCompTitle =
    getTsHeaderLead(view_type, state, true) +
    " Ballots Returned Over Time - Yearly Comparison";

  setDocumentTitle(title);

  //Override general VIEW_TYPE_OPTIONS in main config.js file since we're adding GOVERNOR races Battleground states
  let VIEW_TYPE_OPTIONS = [
    { label: "National", value: "National" },
    { label: "State", value: "state" },
    { label: "Battleground: Senate", value: "SenateBS" },
    { label: "Battleground: Governor", value: "GovernorBS" },
  ];
  return (
    <ConfigContext.Provider value={config}>
      <PageWrapper>
        <PageBand>
          <div className="w-100 pb-3 border-gray-400 ">
            <div className="d-flex flex-row justify-content-between align-items-start">
              <h1 className="mb-0">{title}</h1>
              <ShareButton title={title} />
            </div>
            <span className="small">
              <strong>Last update: </strong>
              {lastModified}
              <VoteShare
                state={state}
                viewType={view_type}
                filePath={config.DATA_FOLDER}
                yearLabels={config.YEAR_LABELS}
                editorialNotes={config.editorialNotes}
              />
            </span>
          </div>
          <div className="w-100 d-flex flex-column flex-md-row-reverse">
            <ScreenReaderDropDownInstructions />
            <form className="d-flex flex-column pl-xl-5 pl-md-2 w-100 w-md-50 w-lg-40 w-xxl-30 font-size-10 font-size-xl-9 font-size-md-8">
              <CompareVoteCountButtons
                countPrefix={countPrefix}
                setCountPrefix={setCountPrefix}
              />
              <div className="d-flex flex-column flex-wrap w-100">
                <DropdownMenu view_type_options={VIEW_TYPE_OPTIONS} />
              </div>
            </form>
            <div className="w-md-50 w-lg-60 w-xxl-70 w-100">
              <div className="bg-white p-3 mb-4">
                <FullScreenWrapper>
                  {demoLoadPercent !== true && (
                    <ProgressBar
                      label={"Loading Data..."}
                      percent={demoLoadPercent}
                      aspectRatio={3 / 8}
                    />
                  )}
                  {demoLoadPercent === true && !!calculatedData && (
                    <HorizontalBar
                      error={demoErrorMessage}
                      data={calculatedData}
                      height={300}
                      width={800}
                      state={state}
                      view_type={view_type}
                    />
                  )}
                </FullScreenWrapper>
              </div>
              <Disclaimers
                showNoRegPartyWarning={showNoRegPartyWarning}
                state={state}
                editorialNotes={config.editorialNotes}
              />
              <div className="w-100 bg-white mb-5">
                {!!filteredData && !!totalTurnout && (
                  <DataTable
                    loading={demoLoadPercent !== true}
                    filteredData={filteredData}
                    years={config.YEAR_LABELS}
                    demo_header={demoHeader}
                    voteCountYears={voteCountYears}
                    totalTurnout={totalTurnout}
                  />
                )}
              </div>
            </div>
          </div>
        </PageBand>
        <PageBand className="bg-gray-300">
          <div className="w-100 mb-4 text-left">
            <h2 tabIndex="0">{modeledPartyTitle}</h2>
            <div className="w-md-50 w-lg-60 w-xxl-70 w-100">
              <div className="bg-white p-3">
                <FullScreenWrapper>
                  {timeSeriesLoadPercent !== true && (
                    <ProgressBar
                      aspectRatio={3 / 8}
                      percent={timeSeriesLoadPercent}
                    />
                  )}
                  {timeSeriesLoadPercent === true && (
                    <ModeledPartyRollup
                      data={modeledPartyRollup || []}
                      years={config.YEARS}
                      view_type={view_type}
                      state={state}
                      height={300}
                      width={800}
                      error={tsErrorMessage}
                      title={modeledPartyTitle}
                    />
                  )}
                </FullScreenWrapper>
              </div>
            </div>
          </div>
        </PageBand>
        <PageBand className="bg-gray-100">
          <div className="w-100 mb-4 text-left">
            <h2 tabIndex="0">{YearlyCompTitle}</h2>
            <div className="w-md-50 w-lg-60 w-xxl-70 w-100">
              <div className="bg-white p-3">
                <FullScreenWrapper>
                  {timeSeriesLoadPercent !== true && (
                    <ProgressBar
                      aspectRatio={3 / 8}
                      percent={timeSeriesLoadPercent}
                    />
                  )}
                  {timeSeriesLoadPercent === true && (
                    <YearlyComparison
                      data={rollupYearComparisons || []}
                      years={config.YEAR_LABELS}
                      view_type={view_type}
                      state={state}
                      height={300}
                      width={800}
                      error={tsErrorMessage}
                      title={YearlyCompTitle}
                    />
                  )}
                </FullScreenWrapper>
              </div>
            </div>
          </div>
        </PageBand>
      </PageWrapper>
    </ConfigContext.Provider>
  );
}
