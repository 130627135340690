import Select from "react-select";
import { DROPDOWN_STYLES } from "./styles";

export default function DemoValueGroup({
  index,
  filter,
  demoOptions,
  demoValueOptions,
  onDemoChange,
  onDemoValueChange,
  onRemoveDemoValueGroup,
}) {
  //Each DemoValueGroup has a Demo (e.g. Age, Race) and Demo Value (e.g. 18-24, 25-34 ) dropdowns
  const demoChange = (e) => {
    onDemoChange(filter.key, e.value);
  };

  const demoValueChange = (e) => {
    onDemoValueChange(filter.key, e.value);
  };

  let selectedDemo = demoOptions?.find((item) => {
    return item?.value === filter.key;
  });

  let selectedDemoVal = demoValueOptions?.find((item) => {
    return item.value === filter.value;
  });

  return (
    <div className="d-flex flex-row w-100 mb-3 ">
      <div style={{ flexBasis: "45%" }}>
        <label id={"demo_label_" + index}>{"Demographic:"}</label>
        <Select
          aria-labelledby={"demo_label_" + index}
          styles={DROPDOWN_STYLES}
          key={"DemographicSelect" + index}
          options={demoOptions}
          value={selectedDemo}
          placeholder={`Select a demo`}
          onChange={demoChange}
        />
      </div>
      <div className="ml-2 flex-fill">
        <label id={"demo_value_label_" + index}>{"Value:"}</label>
        <Select
          aria-labelledby={"demo_value_label_" + index}
          styles={DROPDOWN_STYLES}
          key={"ValueSelect" + index}
          options={demoValueOptions}
          value={selectedDemoVal}
          placeholder={`Select a value`}
          onChange={demoValueChange}
        />
      </div>
      <button
        className="btn p-1 bg-gray-100 ml-2 border-0"
        onClick={(e) => {
          e.preventDefault();
          onRemoveDemoValueGroup(filter.key);
        }}
      >
        <span className="fa fa-close text-gray-800"></span>
        <span className="sr-only">remove demographic selection</span>
      </button>
      <br />
    </div>
  );
}
