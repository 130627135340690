import useExpanded from "./hooks/useExpanded";
import ModalWrapper from "./modalWrapper";

export default function FullScreenWrapper({ children }) {
  let [expanded, setExpanded] = useExpanded();
  if (expanded)
    return (
      <ModalWrapper>
        {children}{" "}
        <button
          className="position-absolute top-0 right-0 border-0 bg-gray-200 m-4 py-1 px-3"
          onClick={() => {
            setExpanded(false);
          }}
        >
          <span className="fa fa-close"></span>
          <span className="sr-only">close full screen graph</span>
        </button>
      </ModalWrapper>
    );
  return (
    <div className={"position-relative w-100"}>
      {children}
      <button
        className="position-absolute bottom-0 right-0 border-0 bg-gray-200 d-md-block d-none"
        onClick={() => {
          setExpanded(true);
        }}
      >
        <span className="fa fa-expand"></span>
        <span className="sr-only">show full screen graph</span>
      </button>
    </div>
  );
}
