import { useQueryParams, StringParam, withDefault } from "use-query-params";
import { VIEW_TYPE_OPTIONS } from "../helpers/config";
import Select from "react-select";
import { DROPDOWN_STYLES } from "./styles";
import React from "react";

export default function ViewTypeDropdown({ custom_options }) {
  const [query, setQuery] = useQueryParams({
    view_type: withDefault(StringParam, "National"),
  });

  let options = !!custom_options ? custom_options : VIEW_TYPE_OPTIONS;
  let label = "View Type";
  let { view_type } = query;
  let selectedItem = options.find((i) => {
    return i.value === view_type;
  });

  const onChange = (e) => {
    setQuery({
      view_type: e.value,
      geo_type: undefined,
      geo: undefined,
      state: undefined,
    });
  };
  return (
    <div className="mb-3">
      <div className="d-flex flex-column">
        <label className="text-nowrap" id="view_type_dropdown_label">
          {label}:
        </label>
        <Select
          aria-labelledby="view_type_dropdown_label"
          styles={DROPDOWN_STYLES}
          options={options}
          value={selectedItem}
          placeholder={`Select a ${label.toLowerCase()}`}
          onChange={onChange}
          defaultValue={options[0]}
        />
      </div>
    </div>
  );
}
