import { useEffect, useState } from "react";

export default function useExpanded() {
  let [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (expanded === true) {
      let escapePress = (e) => {
        if (e.keyCode === 27) setExpanded(false);
      };
      window.addEventListener("keydown", escapePress, false);
      document.body.classList.add("modal-open");
      return () => {
        window.removeEventListener("keydown", escapePress, false);
      };
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [expanded]);

  return [expanded, setExpanded];
}
