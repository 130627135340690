import * as d3 from "d3";

import { BASE_SITE_TITLE, emptyGeoTypeField } from "./config";

let hostname;
if (window.location.hostname === "localhost") {
  hostname = "https://dev-targetearly.targetsmart.com";
} else {
  hostname = window.location.origin;
}

export const HOSTNAME = hostname;

function random_qs(url) {
  return url + "?" + Math.floor(Math.random() * 1000);
}

export function getVoteSharePath(filePath) {
  return random_qs(HOSTNAME + `/data/${filePath}/demos/VoteShare.csv`);
}

export function getDemoFilePath(file_name, file_type, folder) {
  let file_path = hostname + "/data/" + folder + "/demos/";
  if (file_type !== null && file_type !== emptyGeoTypeField) {
    file_path += file_name + "_EV_Numbers." + file_type + ".csv.gz";
  } else {
    file_path += file_name + "_EV_Numbers.csv.gz";
  }
  return random_qs(file_path);
}

export function getTSFilePath(file_name, folder) {
  let file_path = hostname + "/data/" + folder + "/time-series/";
  file_path += file_name + "_TS_Data.csv";
  return random_qs(file_path);
}
export const convertItemsToOptions = (items) => {
  // Convert list of strings into list of objects with label and value for dropdown options
  items.sort().unshift("All");
  let options = [];
  items.forEach((item) => {
    options.push({
      label: item,
      value: item,
    });
  });
  return options;
};

export function safe_division(numerator, denominator) {
  if (denominator === 0 || isNaN(denominator)) {
    return 0;
  } else {
    return numerator / denominator;
  }
}

export function fetch_header(url, wch) {
  var req = new XMLHttpRequest();
  req.open("HEAD", url, false);
  req.send(null);
  if (req.status === 200) {
    return req.getResponseHeader(wch);
  } else return false;
}

export function create_sorting_function(values) {
  return function sort(a, b) {
    let order = {};
    for (let i = 0; i < values.length; i++) {
      order[values[i]] = i + 1;
    }
    if (order[a.key] > order[b.key]) return 1;
    if (order[a.key] < order[b.key]) return -1;
  };
}

export function wrap(text, width) {
  text.each(function () {
    let text = d3.select(this);
    let words = text
      .text()
      .split(/(\s+)|(-)|(\/)/)
      // .split(/\s+/)
      .reverse()
      .filter(Boolean);
    let word;
    let line = [];
    let lineNumber = 0;
    let lineHeight = 1.1; // ems
    let y = text.attr("y");
    let dy = parseFloat(text.attr("dy"));
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", 0)
      .attr("y", y)
      .attr("dy", dy + "em");
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(""));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(""));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
}

export function numberText(number) {
  if (number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return "0";
  }
}

export function numberPercentage(number, total) {
  return total == null
    ? ""
    : " (" + (safe_division(number, total) * 100).toFixed(1) + "%) ";
}

export function getFullStateName(stateCode) {
  let stateNames = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };
  return stateNames[stateCode];
}

export function sortByDateAscending(a, b) {
  return a.key - b.key;
}

export function setDocumentTitle(pageTitle) {
  document.title = pageTitle
    ? `${pageTitle} | ` + BASE_SITE_TITLE
    : BASE_SITE_TITLE;
}
