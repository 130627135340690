import {COUNT_VARIABLE_PREFIX, GEO_TYPE_MAPPING, label_suppressions, registeredParty,} from "./config";
import {safe_division, sortByDateAscending} from "./helpers";
import * as d3 from "d3";
import {nest} from "d3-collection";
import {demographic_sort} from "./graphHelpers";

// ======= DATA CALCULATION FUNCTIONALITY ========

export function filterData(
  data,
  geo,
  geo_type,
  demoFilters,
  count_fields,
  DEMO_MAPPING
) {
  let filtered = data;
  //Filter by Geo Type
  if (geo != null && geo !== "All") {
    filtered = filtered.filter((d) => d[GEO_TYPE_MAPPING[geo_type]] === geo);
  }

  //Filter by Demographics
  for (const filter of demoFilters) {
    if (filter.value !== "All") {
      filtered = filtered.filter(
        (d) => d[DEMO_MAPPING[filter.key]] === filter.value
      );
    }
  }

  //Rollup counts by last selected demographic and COUNT_FIELDs
  let temp_demo = demoFilters[demoFilters.length - 1].key;
  let filteredData = nest()
    .key(function (d) {
      return d[DEMO_MAPPING[temp_demo]];
    })
    .rollup(function (v) {
      let ret = {};
      count_fields.forEach(function (count_field) {
        ret[count_field] = d3.sum(v, function (d) {
          return d[count_field];
        });
      });
      return ret;
    })
    .entries(filtered);

  return filteredData;
}

export const handleNoPartyReg = (
  lastSelectedDemo,
  fd,
  setShowNoRegPartyWarning,
  showNoRegPartyWarning
) => {
  // Show no party registration warning
  if (
    lastSelectedDemo === registeredParty &&
    fd.length === 1 &&
    fd[0].key === "Unaffiliated"
  ) {
    setShowNoRegPartyWarning(true);

    // Hack to add all options with a 0 count in registeredParty filter
    let current_values = fd[0].value;
    let add_options = ["Democrat", "Republican", "Other"];
    let zero_values = Object.fromEntries(
      Object.keys(current_values).map((key) => [key, 0])
    );
    let added_data = add_options.map((option) => ({
      key: option,
      value: zero_values,
    }));
    fd.push(...added_data);
  } else if (showNoRegPartyWarning === true) {
    setShowNoRegPartyWarning(false);
  }
};

export function getTotalTurnout(filteredData) {
  let sum_data = {};
  filteredData.forEach((item) => {
    for (const [key, value] of Object.entries(item.value)) {
      if (key in sum_data) {
        sum_data[key] += value;
      } else {
        sum_data[key] = value;
      }
    }
  });
  return sum_data;
}

export function calculateGraphData(
  filteredData,
  totalTurnout,
  years,
  voteCountYears,
  demoFilters
) {
  let result = [];
  let lastSelectedDemo = demoFilters[demoFilters.length - 1].key;
  demographic_sort(lastSelectedDemo, filteredData);
  let suppressions = label_suppressions[lastSelectedDemo];
  let group = 0;

  filteredData.forEach(function (d) {
    if (!suppressions.includes(d.key)) {
      years.forEach(function (y, i) {
        let vote_count_year = voteCountYears[COUNT_VARIABLE_PREFIX + i];
        result.push({
          year: y,
          index: i,
          key: d["key"],
          vote_count: d["value"][vote_count_year],
          total_turnout: totalTurnout[vote_count_year],
          value: safe_division(
            d["value"][vote_count_year],
            totalTurnout[vote_count_year]
          ),
          group: group,
        });
      });
      group += 1;
    }
  });
  return result;
}

export function calculateRollupYearComparisons(
  data,
  years,
  currentEavCountPrefix,
  daysOutDateType
) {
  var yearlyRollup = nest()
    .key(function (d) {
      return d.Date_Voted;
    })
    .rollup(function (d) {
      let ret = {};
      years.forEach((y, i) => {
        ret["voters_" + i] = d3.sum(d, function (e) {
          return e[currentEavCountPrefix + y];
        });
      });
      return ret;
    })
    .entries(data);

  if (!daysOutDateType) {
    yearlyRollup.forEach(function (d) {
      d.key = new Date(d.key);
    });
  }
  yearlyRollup = yearlyRollup.sort(sortByDateAscending);
  return yearlyRollup;
}

export function calculateRollupModeledParty(
  data,
  countField,
  DEMO_MAPPING,
  daysOutDateType
) {
  var rollup = nest()
    .key(function (d) {
      return d.Date_Voted;
    })
    .rollup(function (v) {
      return {
        dem_voters: d3.sum(v, function (d) {
          if (d[DEMO_MAPPING["modeledParty"]] === "Democrat") {
            return d[countField];
          }
        }),
        gop_voters: d3.sum(v, function (d) {
          if (d[DEMO_MAPPING["modeledParty"]] === "Republican") {
            return d[countField];
          }
        }),
        other_voters: d3.sum(v, function (d) {
          if (d[DEMO_MAPPING["modeledParty"]] === "Unaffiliated") {
            return d[countField];
          }
        }),
      };
    })
    .entries(data);

  if (!daysOutDateType) {
    rollup.forEach(function (d) {
      d.key = new Date(d.key);
    });
  }
  rollup = rollup.sort(sortByDateAscending);
  return rollup;
}
