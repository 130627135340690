import Select from "react-select";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { sendEvent } from "../analytics";
import { GEO_TYPE_OPTIONS } from "../helpers/config";
import GeoValueDropdown from "./geoValueDropdown";

import { DROPDOWN_STYLES } from "./styles";
import { GEO_VALUE_STATEWIDE } from "./urlParams";

export default function GeoTypeDropdown() {
  const geoTypeOptions = GEO_TYPE_OPTIONS;
  const [query, setQuery] = useQueryParams({
    geo_type: withDefault(StringParam, GEO_VALUE_STATEWIDE),
    geo: StringParam,
  });
  // Geo is used when setting the Query value in onChange
  // eslint-disable-next-line no-unused-vars
  let { geo_type, geo } = query;

  let selectedItem = geoTypeOptions.find((i) => {
    return i.value === geo_type;
  });

  let onChange = (e) => {
    sendEvent("Geo Type", "Selected a Geo Type", e.value);
    setQuery({ geo_type: e.value, geo: "All" });
  };

  return (
    <>
      <div className={"d-flex flex-row w-100 "}>
        <div className="d-flex flex-column flex-fill">
          <label id="geo_type">Geo Type</label>
          <Select
            aria-labelledby="geo_type"
            styles={DROPDOWN_STYLES}
            options={geoTypeOptions}
            value={selectedItem}
            onChange={onChange}
          />
        </div>
        {geo_type !== GEO_VALUE_STATEWIDE && (
          <div className="d-flex flex-column ml-2 flex-fill">
            <GeoValueDropdown />
          </div>
        )}
      </div>
    </>
  );
}
