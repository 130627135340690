import * as d3 from "d3";
import {
  create_sorting_function,
  fetch_header,
  getTSFilePath,
} from "./helpers";

export const getColorScale = (years, year_colors) => {
  return d3.scaleOrdinal().domain(years).range(year_colors);
};

export const width = 800;
export const height = 300;

export function drawTargetSmartLogo(svg) {
  //Add TargetSmart logo to graph
  let logo_width = 80;
  let logo_height = 15;
  svg
    .append("svg:image")
    .attr("href", "../../img/logo.svg")
    .attr("x", width - logo_width - 65)
    .attr("y", height - logo_height - 70)
    .attr("width", logo_width)
    .attr("height", logo_height);
}

// ====== INFO/DISCLAIMER METHODS ========
export function get_last_modified(dataFolder, file_name) {
  let mtime = fetch_header(
    getTSFilePath(file_name, dataFolder),
    "Last-Modified"
  );
  if (mtime) {
    return new Intl.DateTimeFormat("en-US", {
      timeStyle: "full",
      dateStyle: "medium",
    }).format(new Date(mtime));
  }
  return false;
}

export function getTsHeaderLead(view_type, state, statewide) {
  const typeMapping = {
    "PresBS": "Pres. Battleground",
    "SenateBS": "Senate Battleground",
    "GovernorBS": "Governor Battleground",
    "BGHDS": "VA HD Battleground",
    "BGSDS": "VA SD Battleground",
    "nonBGSDS": "VA SD Non-Battleground",
    "nonBGHDS": "VA HD Non-Battleground"
  };

  if (typeMapping.hasOwnProperty(view_type)) {
    return typeMapping[view_type];
  } else if (view_type !== "National") {
    let lead = state;
    if (statewide) {
      lead += " Statewide";
    }
    return lead;
  }

  return view_type;
}

export function battlegroundMapping(viewType) {
  let bgMapping = {
    PresBS: "Presidential Battleground States",
    SenateBS: "Senate Battleground States",
    GovernorBS: "Governor Battleground States",
  };
  return bgMapping[viewType];
}

export function demographic_sort(demo, turnout_demo) {
  let sortArrays = {
    voterScore: [
      "First Time Voter",
      "Infrequent Voter",
      "Frequent Voter",
      "Super Voter",
    ],
    age: ["18-29", "30-39", "40-49", "50-64", "65-74", "75+"],
    race: [
      "Asian or Pacific Islander",
      "Black",
      "Hispanic or Latino",
      "White",
      "Uncoded",
      "Other/Uncoded",
    ],
    urbanicity: ["Rural", "Suburban", "Urban"],
    raceEdu: [
      "African-American - College",
      "African-American - Non-College",
      "Asian - College",
      "Asian - Non-College",
      "Caucasian - College",
      "Caucasian - Non-College",
      "Hispanic - College",
      "Hispanic - Non-College",
      "Other/Uncoded",
    ],
    registeredParty: ["Democrat", "Republican", "Other", "Unaffiliated"],
    modeledParty: ["Democrat", "Republican", "Other", "Unaffiliated"],
    genderMaritalStatus: [
      "Female-Not Married",
      "Female-Married",
      "Male-Not Married",
      "Male-Married",
      "Unknown-Married",
      "Unknown-Not Married",
    ],
    votedPreviousElection: ["Did Not Vote", "Voted"],
  };
  if (sortArrays.hasOwnProperty(demo)) {
    turnout_demo.sort(create_sorting_function(sortArrays[demo]));
  }
}

// //   if (select_demo === raceEdu || select_demo2 === raceEdu) {
// //     $("#demo_disclaimer")
// //       .append(
// //         "ℹ️ Race + Education divides Caucasian voters into those with and without a college degree, as research has shown that these groups exhibit quite " +
// //           "different voting patterns.  " +
// //           "<sup><a href='https://www.theatlantic.com/education/archive/2018/11/education-gap-explains-american-politics/575113/' " +
// //           "target='_blank'>[1]</a></sup>"
// //       )
// //       .show();
// //   }

// export function isBattlegroundView(view_type) {
//   return view_type === "PresBS" || view_type === "SenateBS";
// }
// // export function g2020DataReadyMessage() {
// //   $("#battleground").html(
// //     "**2020 General Election Data Not Available for: MS, ND, UT (partial), NY (partial)"
// //   );
// //   // $('#battleground').append(battleground_links_append(has_g2020_eday));
// // }

// // export function noHistoricDataCheck(state) {
// //   //*** Warning for no historic data
// //   if (state in missing_historic_data) {
// //     if (missing_historic_data[state] === "Y") {
// //       $("<p/>", {
// //         text:
// //           "ℹ️ G2020 early & absentee data is not available for 2016 and 2018 in " +
// //           state,
// //       }).appendTo("#unaffiliated_warning");
// //     } else {
// //       $("<p/>", {
// //         text:
// //           "ℹ️ G2020 early & absentee data is not available for " +
// //           missing_historic_data[state] +
// //           " in " +
// //           state,
// //       }).appendTo("#unaffiliated_warning");
// //     }
// //   }
// // }
