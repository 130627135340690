import PageBand from "../pageBand";
import PageWrapper from "../pageWrapper";
import TargetSmartLogoWordMark from "../../targetsmart_design/bootstrap-theme/dist/imgs/logo-word-mark.svg";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { setDocumentTitle } from "../helpers/helpers";

export default function About() {
  setDocumentTitle("About");

  return (
    <PageWrapper>
      <PageBand className="flex-md-row flex-column d-flex align-items-start">
        <div className="flex-shrink-1 about pr-5">
          <div>
            <h3 className="about-handwrite">Who?</h3>
            <p>
              TargetEarly is a product of{" "}
              <a target="blank" href="https://targetsmart.com">
                {" "}
                <img
                  src={TargetSmartLogoWordMark}
                  style={{ height: "1em" }}
                  className="d-inline "
                  aria-hidden={true}
                  alt="targetsmart logo"
                />
                <span className="sr-only">TargetSmart</span>
              </a>
              , a Democratic political data and data services firm.
            </p>
            <p>
              At TargetSmart, we make data better every day. Making better data
              helps our clients make the right decisions, define their audiences
              more intelligently, and communicate in a more impactful and
              cost-effective way. TargetSmart's expert team of data, political,
              direct marketing, and technical professionals wakes up every day
              with one objective: to help our clients win with data.
            </p>
            <h3 className="about-handwrite">What?</h3>
            <p>
              TargetEarly is a dashboard for exploring early in-person and
              absentee voting trends.
            </p>

            <p id="readfirst">
              For additional context, please read{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://medium.com/@tombonier/early-and-mail-balloting-in-the-2020-election-analysis-and-reporting-e5996852367f"
              >
                Early and Mail Balloting in the 2020 Election — Analysis and
                Reporting
              </a>{" "}
              by TargetSmart CEO{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/tbonier"
              >
                Tom Bonier
              </a>{" "}
              on Medium.com.
            </p>

            <h3 className="about-handwrite">When?</h3>
            <p>
              Leading into major elections, TargetEarly is updated daily with
              new early and absentee voting activity collected from state and
              county election offices.
            </p>
            <p>
              TargetSmart's early vote data will always lag 1-2 days behind the
              official election authority's reporting.
            </p>
            <p>
              Demographic and registration statistics will also lag behind the
              official election authority.
            </p>

            <h3 className="about-handwrite">Where?</h3>
            <p>
              Absentee ballots requested/received and early vote period
              in-person votes cast data is made available by US state and county
              election offices. When this new data becomes available,
              TargetSmart acquires and adds it to TargetEarly.
            </p>

            <h3 className="about-handwrite">How?</h3>

            <p>
              Leading into major elections, TargetSmart collects and aggregates
              early vote returns from states each day. Early vote data is then
              linked to our national voter registration database, where we can
              observe and report on the demographic and political composition of
              individuals that have voted early, absentee, or have requested a
              ballot.
            </p>

            <h3 className="about-handwrite">Why?</h3>
            <p>
              The TargetEarly site grew out of the early and absentee reporting
              TargetSmart performs internally and for our clients. We've decided
              to make this information publicly available because we believe
              that voting is a fundamental right that should be celebrated,
              protected, and expanded. Our hope is that TargetEarly will provide
              useful insight and motivate more Americans to participate in the
              process.
            </p>
            <TwitterTweetEmbed tweetId={"1308580592938778624"} />
          </div>
        </div>
        <div
          className="flex-basis-400 flex-basis-lg-200 bg-white p-3"
          style={{ minWidth: "10em" }}
          id="about-sidebar"
        >
          <div id="contact">
            <h4>Contact Us</h4>
            <div className="small">
              <p>
                <a href="mailto:sales@targetsmart.com">
                  <i className="fas fa-envelope mr-2"></i>
                  <strong>Sales</strong>
                  <br />
                </a>{" "}
                Ask us about using our early vote custom audiences in your
                digital ad targeting. TargetSmart can also help you exclude
                known early voters from your GOTV universe.
              </p>
              <p>
                Contact us{" "}
                <a
                  href="https://targetsmart.com/#hpcontactus"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                for more details.
              </p>
              <p>
                <a href="mailto:press@targetsmart.com">
                  <i className="fas fa-envelope mr-2"></i>
                  Press
                </a>
              </p>
            </div>
          </div>

          <div id="faq">
            <h4>Frequently Asked Questions</h4>
            <div className="small">
              <p className="about-question">
                What is meant by "Modeled Democrat" and "Modeled GOP"?
              </p>
              <p>
                When official party registration information is not available
                from government sources, TargetSmart does the next best thing
                which is to predict the party affiliation of voters.
                <br />
                <a href="modeling">Learn more</a>
              </p>
              <p className="about-question">
                <strong>
                  How are modeled Democrat and Republican party alignment
                  determined?
                </strong>
              </p>
              <p>
                TargetSmart has developed a proprietary classNameification model
                to predict the likely political party affiliation of individual
                voters.
                <a href="./modeling">Learn more</a>
              </p>

              <p className="about-question">
                <strong>Why aren't all fifty states and DC here?</strong>
              </p>
              <p>
                TargetSmart collects early and absentee vote information from
                state governments. This site only displays the states we are
                tracking. There are various reasons why we may not be tracking a
                specific state. Common reasons include when a state hasn't
                started early voting or provides insufficient data.
              </p>
              <p className="about-question">
                <strong>
                  Are data for all counties being collected for each state?
                </strong>
              </p>
              <p>
                For the majority of states, all counties are being collected.
                For other states, we aren't able to collect for all counties.
              </p>
              <p className="about-question">
                <strong>
                  Why are the counts provided by TargetSmart different than
                  Secretary of State numbers?
                </strong>
              </p>
              <p>
                There are a few reasons why our numbers may differ from those
                provided by a secretary of state. Most importantly, the
                secretary of state has access to some self-reported data that is
                not available to TargetSmart and is therefore modeled or
                acquired from other sources. The exact availability of this data
                varies by state, but this can apply to the race, age, or gender
                fields. Additionally, there can be some variation in these
                counts depending on when our data was updated following the
                election. We make every attempt to control for this variation
                and establish a good snapshot from Election day, but there is
                still some variation.
              </p>
            </div>
          </div>
          <hr />
          <div className="small">
            <strong>Disclaimer</strong>
            <p>
              TargetSmart makes no warranty, expressed or implied, as to the
              results obtained from the use of the information on the
              TargetEarly website.
            </p>
          </div>
        </div>
      </PageBand>
    </PageWrapper>
  );
}
