import { useEffect, useRef, useState } from "react";
import {
  calculateRollupModeledParty,
  calculateRollupYearComparisons,
} from "../helpers/dataCalculation";
import { getTimeSeriesData } from "../helpers/services";

export default function useTimeSeries({ config, geo }) {
  let activeTimeSeriesRequest = useRef(false);
  let [timeSeriesLoadPercent, setTimeSeriesDemoLoadPercent] = useState(true);
  let [tsErrorMessage, setTsErrorMessage] = useState(false);
  let [modeledPartyRollup, setModeledPartyRollup] = useState(null);
  let [rollupYearComparisons, setRollupYearComparisons] = useState(null);

  useEffect(() => {
    setTimeSeriesDemoLoadPercent(0);
    if (!!activeTimeSeriesRequest.current)
      activeTimeSeriesRequest.current.abort();
    activeTimeSeriesRequest.current = getTimeSeriesData(
      geo,
      config.DATA_FOLDER,
      setTimeSeriesDemoLoadPercent
    );
    activeTimeSeriesRequest.current.promise
      .then((data) => {
        setModeledPartyRollup(
          calculateRollupModeledParty(
            data,
            config.TS_MODELED_PARTY_COUNT_FIELD,
            config.DEMOGRAPHIC_MAPPING,
            config.DAYS_OUT_DATE_TYPE === undefined
              ? true
              : config.DAYS_OUT_DATE_TYPE
          )
        );
        setRollupYearComparisons(
          calculateRollupYearComparisons(
            data,
            config.YEARS,
            config.COUNT_PREFIXES.TS_COUNT_PREFIX,
            config.DAYS_OUT_DATE_TYPE === undefined
              ? true
              : config.DAYS_OUT_DATE_TYPE
          )
        );
        setTimeSeriesDemoLoadPercent(true);
        setTsErrorMessage(false);
      })
      .catch((e) => {
        setTimeSeriesDemoLoadPercent(true);
        console.error(e);
        if (e.type === "basic") {
          // Data not present in S3
          setTsErrorMessage(`Data is not available for ${geo}`);
        } else {
          setTsErrorMessage("An error occurred while loading data");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo]);

  return {
    timeSeriesLoadPercent,
    tsErrorMessage,
    modeledPartyRollup,
    rollupYearComparisons,
  };
}
