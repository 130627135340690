import { useEffect, useState } from "react";

export function ToolTip({ children, show, top, left, width, arrowOffset }) {
  let [flip, setFlip] = useState(false);
  width = !!width ? width : 200;
  arrowOffset = !!arrowOffset ? arrowOffset : 50;
  useEffect(() => {
    if (parseInt(width) + parseInt(left) + arrowOffset > window.innerWidth) {
      if (!flip) setFlip(true);
    } else {
      if (flip) setFlip(false);
    }
  }, [show, top, left, width, flip, arrowOffset]);
  return (
    <div
      className={
        "bg-gray-100 rounded position-fixed shadow-sm pointer-events-none bubble-arrow-above " +
        (!show ? "d-none" : "d-block")
      }
      style={{
        top: top,
        // left: flip ? left - width : left,
        left: left,
        width: width + "px",
        zIndex: 22,
      }}
    >
      <div
        className="position-absolute w-100 shadow-sm rounded "
        style={{
          marginTop: "-.75em",
          left: flip ? arrowOffset * -1 : arrowOffset,
        }}
      >
        {children}
      </div>
    </div>
  );
}
