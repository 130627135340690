import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { ToolTip } from "../toolTip";
import { numberText } from "../helpers/helpers";
import { drawModeledPartyRollupTS } from "../helpers/timeSeriesHelpers";
import { TS_MODELED_PARTIES_MAP } from "../helpers/config";
import ChartError from "./ChartError";
import TimeSeriesTable from "./timeSeriesTable";

export function ModeledPartyRollup({
  height,
  width,
  data,
  view_type,
  state,
  error,
  title,
}) {
  const svgRef = useRef(null);
  let [toolTipData, setToolTipData] = useState({
    show: false,
    top: 0,
    left: 0,
  });

  if (toolTipData.key instanceof Date) {
    const formatTime = d3.timeFormat("%m/%d");
    toolTipData.key = formatTime(toolTipData.key);
  }
  const setToolTip = (newData) => {
    let tooltip = Object.assign(toolTipData, newData);
    setToolTipData({ ...tooltip });
  };

  let isDateType = data.length > 1 && data[0].key instanceof Date;
  let x_label = isDateType ? "Date of Votes Cast" : "Days out from election";

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    drawModeledPartyRollupTS(svg, data, view_type, state, x_label, setToolTip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let tableLabels = Object.keys(TS_MODELED_PARTIES_MAP).map((key) => {
    return TS_MODELED_PARTIES_MAP[key].label;
  });

  let tableKeys = Object.keys(TS_MODELED_PARTIES_MAP);

  return (
    <>
      <svg
        ref={svgRef}
        viewBox={"0 0 " + width + " " + height}
        style={{ display: !error ? "inherit" : "none" }}
        aria-hidden={true}
      />
      {!error && (
        <TimeSeriesTable
          data={data}
          dataKeys={tableKeys}
          headers={[x_label, ...tableLabels]}
          title={title}
        />
      )}
      {!!error && (
        <ChartError
          error={error}
          buttonText={"Try again"}
          height={height}
          width={width}
        />
      )}
      <ToolTip
        show={toolTipData.show}
        left={toolTipData.left - 98}
        top={toolTipData.top + 35}
        width={210}
      >
        <div className=" d-flex flex-column mb-0">
          <div className="d-flex align-items-center bg-gray-100 p-2 rounded-top border-bottom">
            {toolTipData.countName && (
              <div
                className="mr-1"
                style={{
                  height: "1em",
                  width: "1em",
                  backgroundColor:
                    TS_MODELED_PARTIES_MAP[toolTipData.countName].color,
                }}
              ></div>
            )}
            {toolTipData.color_item}
          </div>
          <div className="pt-2 p-2 bg-white rounded-bottom">
            <div>
              {x_label}:{" "}
              <span className="font-weight-bold">{toolTipData.key}</span>
            </div>{" "}
            <div>
              Vote Count:{" "}
              <span className="font-weight-bold">
                {numberText(toolTipData.value)}
              </span>
            </div>
          </div>
        </div>
      </ToolTip>
    </>
  );
}
