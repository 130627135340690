import { useContext } from "react";
import Select from "react-select";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { ConfigContext } from "../ConfigContext";
import GeoTypeDropdown from "./geoTypeDropdown";
import { DROPDOWN_STYLES } from "./styles";

export default function UsStateDropdown() {
  const config = useContext(ConfigContext);
  const states = config.states;
  const [query, setQuery] = useQueryParams({
    state: withDefault(StringParam, states[0].value),
  });

  let { state } = query;

  let selectedItem = states.find((item) => {
    return item.value === state;
  });

  return (
    <>
      <div className="mb-3">
        <label id="state_select">State</label>
        <Select
          aria-labelledby="state_select"
          styles={DROPDOWN_STYLES}
          options={states}
          value={selectedItem}
          defaultValue={states[0]}
          onChange={(e) => {
            setQuery({
              state: e.value,
              geo: undefined,
              geo_type: undefined,
            });
          }}
        />
      </div>
      <div className="mb-3">
        <GeoTypeDropdown />
      </div>
    </>
  );
}
