import { useEffect, useRef, useState } from "react";
import { STATE_PARAM } from "../dropdowns/urlParams";
import {
  calculateGraphData,
  filterData,
  getTotalTurnout,
  handleNoPartyReg,
} from "../helpers/dataCalculation";
import { fetchDemoData } from "../helpers/services";

export default function useDemoData({
  config,
  geo_type,
  geo,
  demo_filters,
  state,
  countPrefix,
  voteCountYears,
  view_type,
}) {
  let activeDemoRequest = useRef(false);

  let [demoLoadPercent, setDemoLoadPercent] = useState(true);
  let [filteredData, setFilteredData] = useState(null);
  let [totalTurnout, setTotalTurnout] = useState(null);
  let [calculatedData, setCalculatedData] = useState([]);
  let [demoErrorMessage, setDemoErrorMessage] = useState(false);

  let [lastModified, setLastModified] = useState("");
  let [showNoRegPartyWarning, setShowNoRegPartyWarning] = useState(false);
  let [demoHeader, setDemoHeader] = useState("");

  const checkHistoricDataPresent = () => {
    let state_data = config.editorialNotes?.find((d) => {
      return d.State === state;
    });
    // Only show 'No Early Vote Data' error message when we know there is NoHistoricData from the editorial_notes.csv file
    // AND 'Final election' radio button is not selected
    if (
      state_data?.NoHistoricData === "Y" &&
      !voteCountYears.voteCountYear0.includes("final_eday")
    ) {
      setDemoErrorMessage(
        `There is no early vote data available for ${state}.`
      );
    }
  };

  const RACE_VALUE_REMAP = {
      "African-American": "Black",
      "Asian": "Asian or Pacific Islander",
      "Caucasian": "White",
      "Hispanic": "Hispanic or Latino",
      "Native American": "Native American",
      "Other/Uncoded": "Other/Uncoded",
      "B": "Black",
      "A": "Asian or Pacific Islander",
      "H": "Hispanic or Latino",
      "W": "White",
      "O": "Other/Uncoded",
      "U": "Other/Uncoded"
}

  const remapRaceField = () => {
    // See https://targetsmart.atlassian.net/browse/SE-317
    if (config.VIEW_DATA) {
      const remappedData = config.VIEW_DATA.map(item => {
        if (RACE_VALUE_REMAP.hasOwnProperty(item.race)) {
          return {
            ...item,
            race: RACE_VALUE_REMAP[item.race]
          };
        }
        return item;
      });
      config.VIEW_DATA = remappedData;
    }
  };


  const calculateData = () => {
    let fd = filterData(
      config.VIEW_DATA,
      geo,
      geo_type,
      demo_filters,
      config.COUNT_FIELDS,
      config.DEMOGRAPHIC_MAPPING
    );
    setFilteredData(fd);

    let lastSelectedDemo = demo_filters[demo_filters.length - 1].key;
    handleNoPartyReg(
      lastSelectedDemo,
      fd,
      setShowNoRegPartyWarning,
      showNoRegPartyWarning
    );

    let tt = getTotalTurnout(fd);
    setTotalTurnout(tt);
    setCalculatedData(
      calculateGraphData(fd, tt, config.YEARS, voteCountYears, demo_filters)
    );

    let demoLabel = config.DEMO_OPTIONS.find((d) => {
      return d.value === lastSelectedDemo;
    })?.label;
    setDemoHeader(demoLabel);
    setDemoLoadPercent(true);
    setDemoErrorMessage(false);
    if (view_type === "state") {
      checkHistoricDataPresent(state);
    }
  };

  useEffect(() => {
    if (!!config.VIEW_DATA) {
      remapRaceField();
      calculateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo, demo_filters, countPrefix]);

  useEffect(() => {
    setDemoLoadPercent(0);
    if (!!activeDemoRequest.current) activeDemoRequest.current.abort();
    let file_name = view_type === STATE_PARAM ? state : view_type;
    activeDemoRequest.current = fetchDemoData(
      file_name,
      geo_type,
      config.DATA_FOLDER,
      setDemoLoadPercent
    );
    activeDemoRequest.current.promise
      .then(({ data, modTime }) => {
        setLastModified(modTime);
        config.VIEW_DATA = data;
        setDemoLoadPercent(1);
        remapRaceField();
        calculateData();
      })
      .catch((e) => {
        setDemoErrorMessage("An error occurred while fetching data");
        setDemoLoadPercent(true);
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo_type, state, view_type]);

  return {
    demoLoadPercent,
    filteredData,
    totalTurnout,
    calculatedData,
    demoErrorMessage,
    lastModified,
    showNoRegPartyWarning,
    demoHeader,
  };
}
