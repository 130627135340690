import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import useGoogleAnalytics from "./components/hooks/useGoogleAnalytics";
import NotFound404 from "./components/pages/404";
import About from "./components/pages/about";
import Cookies from "./components/pages/cookies";
import G2020 from "./components/pages/g2020/g2020";
import G2021 from "./components/pages/g2021/g2021";
import G2022 from "./components/pages/g2022/g2022";
import Home from "./components/pages/home";
import Modeling from "./components/pages/modeling";
import R2021 from "./components/pages/r2021/r2021";
import R2022 from "./components/pages/r2022/r2022";
import RedirectOldUrls from "./components/redirectOldUrls";
import SalesBanner from "./components/salesBanner";
import SrNavigationAlert from "./components/srNavigationAlert";
import G2023 from "./components/pages/g2023/g2023";

function App() {
  useGoogleAnalytics();
  return (
    <div className="d-flex justify-content-start align-items-center flex-column min-h-100">
      <header className="w-100">
        <Switch>
          <Route path="/" exact>
            <SalesBanner />
          </Route>
        </Switch>
        <Header />
      </header>
      <main className="w-100">
        <Switch>
          <Route path={"/*.html"} exact>
            <RedirectOldUrls />
          </Route>
          <Route path={"/g2020*"}>
            <SrNavigationAlert>navigated to US 2020 General</SrNavigationAlert>
            <G2020 />
          </Route>
          <Route path="/r2021*">
            <SrNavigationAlert>
              navigated to Georgia 2021 Senate Runoff
            </SrNavigationAlert>
            <R2021 />
          </Route>
          <Route path={"/current*"}>
            <Redirect to="/g2022" />
          </Route>
          <Route path="/g2022" exact>
            <SrNavigationAlert>navigated to US 2022 Midterms</SrNavigationAlert>
            <G2022 />
          </Route>
          <Route path="/g2021" exact>
            <SrNavigationAlert>navigated to NJ &amp; VA 2021</SrNavigationAlert>
            <G2021 />
          </Route>
          <Route path="/r2022" exact>
            <SrNavigationAlert>
              navigated to Georgia 2022 Senate Runoff
            </SrNavigationAlert>
            <R2022 />
          </Route>
          <Route path="/g2023" exact>
            <SrNavigationAlert>
              navigated to 2023 General
            </SrNavigationAlert>
            <G2023 />
          </Route>
          <Route path="/about">
            <SrNavigationAlert>
              navigated to TargetEarly about page
            </SrNavigationAlert>
            <About />
          </Route>
          <Route path="/modeling">
            <SrNavigationAlert>navigated to Modeling page</SrNavigationAlert>
            <Modeling />
          </Route>
          <Route path="/cookies">
            <SrNavigationAlert>
              navigated to TargetEarly Cookie Policy
            </SrNavigationAlert>
            <Cookies />
          </Route>
          <Route path="/" exact>
            <SrNavigationAlert>navigated to TargetEarly home</SrNavigationAlert>
            <Home />
          </Route>
          <Route path="*">
            <SrNavigationAlert>navigated to 404 page</SrNavigationAlert>
            <NotFound404 />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
}

export default App;
