export default function ModalWrapper({ children, className = "" }) {
  return (
    <div
      style={{ zIndex: 21 }}
      className={"position-fixed top-0 left-0 vw-100 vh-100 p-3 " + className}
    >
      <div className="bg-white p-5 w-100 h-100 d-flex flex-column align-items-start justify-content-center shadow-lg ">
        {children}
      </div>
    </div>
  );
}
