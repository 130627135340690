export const BASE_SITE_TITLE = "TargetEarly by 🎯 TargetSmart";
export const TS_MODELED_PARTIES = ["Dem", "GOP", "Other"];
export const HISTORIC_YEARS = ["2016", "2018", "2020"];

export const FINAL_EAV_COUNT_PREFIX = "Final_eav_voted_count_";
export const CURRENT_EAV_COUNT_PREFIX = "Total_voted_count_";
export const FINAL_EDAY_COUNT_PREFIX = "Final_eday_voted_count_";
export const COUNT_VARIABLE_PREFIX = "voteCountYear";

export const HISTORIC_VIEW = "HISTORIC";

export const HISTORIC_TS_MODELED_PARTY_COUNT = "Total_voted_count_2020";
export const VOTESHARE_PATH = "/data/g2020/demos/VoteShare.csv";

//USE as constants for Demographic option VALUES
export const modeledParty = "modeledParty";
export const registeredParty = "registeredParty";
export const voterScore = "voterScore";
export const age = "age";
export const gender = "gender";
export const genderMaritalStatus = "genderMaritalStatus";
export const maritalStatus = "maritalStatus";
export const race = "race";
export const raceEdu = "raceEdu";
export const urbanicity = "urbanicity";
export const voteSimilarPriorElection = "voteSimilarPriorElection";
export const voteSimilarPriorElection_2 = "voteSimilarPriorElection_2";
export const emptyGeoTypeField = "statewide";
export const education = "education";

export const URL_PARAM_LABELS = {
  modeledParty: "Modeled Party",
  registeredParty: "Registered Party",
  voterScore: "Voter Score",
  age: "Age",
  gender: "Gender",
  genderMaritalStatus: "Gender + Marital Status",
  maritalStatus: "Marital Status",
  race: "Race",
  raceEdu: "Race + Education",
  urbanicity: "Urbanicity",
  voteSimilarPriorElection: "Voted in 2016",
  education: "Education",
};

export var label_suppressions = {
  age: ["Unknown"],
  urbanicity: ["Unknown"],
  voterScore: [],
  gender: [],
  registeredParty: [],
  modeledParty: [],
  raceEdu: [],
  genderMaritalStatus: [],
  voteSimilarPriorElection: [],
  voteSimilarPriorElection_2: [],
  education: [],
  maritalStatus: [],
  race: [],
};

export const GEO_TYPE_MAPPING = {
  cds: "vf_cd",
  sds: "vf_sd",
  hds: "vf_hd",
  counties: "vf_county_name",
  dmas: "reg_dma_name",
};

// export const RADIO_SELECTION_MAPPINGS = {
//   final_eav: FINAL_EAV_COUNT_PREFIX,
//   current_eav: CURRENT_EAV_COUNT_PREFIX,
//   final_eday: FINAL_EDAY_COUNT_PREFIX, //default
// };

// //COLORS
export const TS_MODELED_PARTIES_COLORS = ["#0256A4", "#EE3526", "#939597"];

export const TS_MODELED_PARTIES_MAP = {
  dem_voters: { color: "#0256A4", label: "Dem" },
  gop_voters: { color: "#EE3526", label: "GOP" },
  other_voters: { color: "#939597", label: "Other" },
};

export function createGroupCountFields(years, prefixes) {
  let count_fields = [];
  count_fields = count_fields.concat(
    createCountFields(prefixes.CURRENT_EAV_COUNT_PREFIX, years)
  );
  count_fields = count_fields.concat(
    createCountFields(prefixes.FINAL_EAV_COUNT_PREFIX, years)
  );
  count_fields = count_fields.concat(
    createCountFields(prefixes.FINAL_EDAY_COUNT_PREFIX, years)
  );
  return count_fields;
}

export function createCountFields(prefix, years) {
  let fields = [];
  years.forEach((y) => {
    fields.push(prefix + y);
  });
  return fields;
}

export const VIEW_TYPE_OPTIONS = [
  { label: "National", value: "National" },
  { label: "State", value: "state" },
  { label: "Battleground: President", value: "PresBS" },
  { label: "Battleground: Senate", value: "SenateBS" },
];

export const GEO_TYPE_OPTIONS = [
  { label: "Statewide", value: "statewide" },
  { label: "County", value: "counties" },
  { label: "Congressional District", value: "cds" },
  { label: "State Senate District", value: "sds" },
  { label: "State House District", value: "hds" },
  { label: "Media Market", value: "dmas" },
];
export const GEO_TYPE_OPTIONS_MAPPING = {
  counties: "vf_county_name",
  hds: "vf_hd",
  cds: "vf_cd",
  sds: "vf_sd",
  dmas: "reg_dma_name",
};

export let US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export let STATE_OPTIONS = [];
for (const state in US_STATES) {
  STATE_OPTIONS.push({ label: US_STATES[state], value: state });
}
