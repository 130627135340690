import {
  age,
  createGroupCountFields,
  gender,
  GEO_TYPE_OPTIONS,
  GEO_TYPE_OPTIONS_MAPPING,
  maritalStatus,
  modeledParty,
  race,
  raceEdu,
  registeredParty,
  urbanicity,
  voterScore,
  voteSimilarPriorElection,
} from "../../helpers/config";

const TITLE = "2021";
const DATA_FOLDER = "g2021";
const FINAL_EAV_COUNT_PREFIX = "final_eav_voted_count_";
const CURRENT_EAV_COUNT_PREFIX = "current_eav_voted_count_";
const FINAL_EDAY_COUNT_PREFIX = "final_eday_voted_count_";
const TS_MODELED_PARTY_COUNT_FIELD = "count_cy";
const COUNT_PREFIXES = {
  FINAL_EAV_COUNT_PREFIX: FINAL_EAV_COUNT_PREFIX,
  CURRENT_EAV_COUNT_PREFIX: CURRENT_EAV_COUNT_PREFIX,
  FINAL_EDAY_COUNT_PREFIX: FINAL_EDAY_COUNT_PREFIX,
  TS_COUNT_PREFIX: "count_",
};
const YEAR_LABELS = ["2017", "2019", "2020", "2021"];
const YEARS = ["cyminus3", "cyminus2", "cyminus1", "cy"];
const YEAR_MAPPING = Object.assign(
  ...YEARS.map((k, i) => ({ [k]: YEAR_LABELS[i] }))
);
let COUNT_FIELDS = createGroupCountFields(YEARS, COUNT_PREFIXES);

const DEMOGRAPHIC_MAPPING = {
  modeledParty: "party_score_rollup",
  registeredParty: "party_rollup",
  voterScore: "voterbase_voter_score",
  age: "age_bucket",
  race: "race",
  raceEdu: "race_education",
  gender: "voterbase_gender",
  urbanicity: "urbanicity",
  maritalStatus: "marital_status",
  voteSimilarPriorElection: "vote_similar_prior_election",
};

const DEMOGRAPHIC_OPTIONS = [
  { label: "Modeled Party", value: modeledParty },
  { label: "Registered Party", value: registeredParty },
  { label: "Vote Frequency", value: voterScore },
  { label: "Age", value: age },
  { label: "Race", value: race },
  { label: "Gender", value: gender },
  { label: "Marital Status", value: maritalStatus },
  { label: "Urbanicity", value: urbanicity },
  { label: "Voted in 2017", value: voteSimilarPriorElection },
  { label: "Race + Education", value: raceEdu },
];

const YEAR_COLORS = ["#457b9d", "#cfdeef", "#1e3556", "#446296"];

const states = [
  { label: "New Jersey", value: "NJ" },
  { label: "Virginia", value: "VA" },
];

const isCurrent = false;
let config = {
  TITLE: TITLE,
  states: states,
  DEMO_OPTIONS: DEMOGRAPHIC_OPTIONS,
  DEMOGRAPHIC_MAPPING: DEMOGRAPHIC_MAPPING,
  YEARS: YEARS,
  YEAR_COLORS: YEAR_COLORS,
  COUNT_FIELDS: COUNT_FIELDS,
  COUNT_PREFIXES: COUNT_PREFIXES,
  GEO_TYPE_OPTIONS_MAPPING: GEO_TYPE_OPTIONS_MAPPING,
  GEO_TYPE_OPTIONS: GEO_TYPE_OPTIONS,
  TS_MODELED_PARTY_COUNT_FIELD: TS_MODELED_PARTY_COUNT_FIELD,
  DATA_FOLDER: DATA_FOLDER,
  YEAR_LABELS: YEAR_LABELS,
  YEAR_MAPPING: YEAR_MAPPING,
  isCurrent: isCurrent,
};
export { config };
