import { useContext, useMemo } from "react";
import { JsonParam, useQueryParams, withDefault } from "use-query-params";
import { sendEvent } from "../analytics";
import { ConfigContext } from "../ConfigContext";
import { convertItemsToOptions } from "../helpers/helpers";
import DemoValueGroup from "./demoValueGroup";

export default function DemoMenu() {
  // High level manager to keep track of all DemoValueGroups

  let [query, setQuery] = useQueryParams({
    demo_filters: withDefault(JsonParam, [
      { key: "modeledParty", value: "All" },
    ]),
  });

  let { demo_filters } = query;

  const config = useContext(ConfigContext);
  const demoOptions = config.DEMO_OPTIONS;

  const getDemoValueOptions = (demo) => {
    let demoValOptions = [
      ...new Set(
        config.VIEW_DATA?.map((item) => item[config.DEMOGRAPHIC_MAPPING[demo]])
      ),
    ];
    return convertItemsToOptions(demoValOptions);
  };

  const demoValueOptions = useMemo(() => {
    return demo_filters.map((d) => {
      return getDemoValueOptions(d.key);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo_filters, config.VIEW_DATA]);

  let lastFilter = demo_filters[demo_filters.length - 1];

  let unusedDemoOptions = [];
  for (let a = 0; a < demoOptions.length; a++) {
    let demoIsUnused = true;
    let demo = demoOptions[a];
    for (let i = 0; i < demo_filters.length; i++) {
      const filter = demo_filters[i];
      if (demo.value === filter.key) demoIsUnused = false;
    }
    if (demoIsUnused) unusedDemoOptions.push(demo);
  }

  function demoChange(oldDemo, newDemo) {
    //Record event for Analytics
    sendEvent("Demographic", "Selected a Demographic", newDemo);

    let isLastDemo =
      lastFilter ===
      demo_filters.find((d) => {
        return d.key === oldDemo;
      });

    demo_filters.map((d) => {
      if (isLastDemo && d.key === oldDemo) {
        d.key = newDemo;
        d.value = "All";
      } else if (d.key === oldDemo) {
        //If the selected filter is not the last one,
        //set the filter's value to the first option (after 'All') of the newDemo
        d.key = newDemo;
        d.value = getDemoValueOptions(newDemo)[1].value;
      }
      return d;
    });
    setQuery({
      demo_filters: [...demo_filters],
    });
  }

  function demoValueChange(demoKey, newDemoValue) {
    sendEvent("Demographic", "Selected a Demographic Value", newDemoValue);

    demo_filters.map((v) => {
      if (v.key === demoKey) v.value = newDemoValue;
      return v;
    });
    let isLastDemo =
      lastFilter ===
      demo_filters.find((d) => {
        return d.key === demoKey;
      });
    if (isLastDemo && newDemoValue !== "All") {
      setQuery({
        demo_filters: [
          ...demo_filters,
          { key: unusedDemoOptions[0].value, value: "All" },
        ],
      });
    } else {
      setQuery({
        demo_filters: [...demo_filters],
      });
    }
  }

  function removeDemoValueGroup(demoKey) {
    let newDemoFilters = demo_filters.filter((v) => {
      return v.key !== demoKey;
    });
    if (newDemoFilters.length === 0) {
      setQuery({
        demo_filters: null,
      });
    } else {
      newDemoFilters[newDemoFilters.length - 1].value = "All";
      setQuery({
        demo_filters: [...newDemoFilters],
      });
    }
  }

  const filterDropdowns = demo_filters.map((filter, i) => {
    let demoOpts = [
      demoOptions.find((opt) => {
        return opt.value === filter.key;
      }),
      ...unusedDemoOptions,
    ];

    let demoValOpts = !!demoValueOptions[i] ? [...demoValueOptions[i]] : [];
    if (i !== demo_filters.length - 1) {
      demoValOpts.shift(); // remove "All" unless it's the last demo selector
    } else {
      if (demo_filters.length === 3) {
        // leave only "All" option if there are 3 filters
        demoValOpts = !!demoValOpts.length ? [demoValOpts[0]] : [];
      }
    }

    return (
      <DemoValueGroup
        filterLength={demo_filters.length}
        key={"filter" + i}
        index={i}
        filter={filter}
        demoOptions={demoOpts}
        demoValueOptions={demoValOpts}
        onDemoChange={demoChange}
        onDemoValueChange={demoValueChange}
        onRemoveDemoValueGroup={removeDemoValueGroup}
      />
    );
  });
  return (
    <div className="w-100">
      <div className="h6">Filters:</div>
      {filterDropdowns}
      {demo_filters.length === 3 && (
        <p className={"small text-danger"}>Maximum of 3 filters reached.</p>
      )}
    </div>
  );
}
