import UsStateDropdown from "./usStateDropdown";
import DemoMenu from "./demoMenu";
import React from "react";

export default function LiteDropdownMenu() {
  //  Use "Lite" dropdown menu when View Type selection is not necessary
  return (
    <>
      <UsStateDropdown />
      <DemoMenu />
    </>
  );
}
