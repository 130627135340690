import { useState } from "react";
import { sendEvent } from "../analytics";
import useScrollStatus from "./useScrollStatus";

export default function usePagePercentage() {
  //Send scroll stats to Google Analytics
  let scrollStatus = useScrollStatus();

  let [is25Hit, setIs25Hit] = useState(false);
  let [is50Hit, setIs50Hit] = useState(false);
  let [is75Hit, setIs75Hit] = useState(false);
  let [is100Hit, setIs100Hit] = useState(false);

  const checkPagePercentage = (isHit, setIsHit, percent) => {
    if (!isHit && scrollStatus.position >= percent) {
      setIsHit(true);
      sendEvent("Scroll", `Scrolled to ${percent}`);
    }
  };

  checkPagePercentage(is25Hit, setIs25Hit, 25);
  checkPagePercentage(is50Hit, setIs50Hit, 50);
  checkPagePercentage(is75Hit, setIs75Hit, 75);
  checkPagePercentage(is100Hit, setIs100Hit, 100);
}
