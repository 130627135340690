import ViewTypeDropdown from "./viewTypeDropdown";
import UsStateDropdown from "./usStateDropdown";
import DemoMenu from "./demoMenu";
import React from "react";
import {StringParam, useQueryParams, withDefault} from "use-query-params";
import {STATE_PARAM} from "./urlParams";

export default function DropdownMenu({view_type_options, default_view}) {
    //  Use this DropdownMenu (instead of LiteDropdownMenu) when you want to include the View Type Dropdown
    // Optional default_view parameter to set a default view type. This is used for the G2023 page

    let [query] = useQueryParams({
        view_type: withDefault(StringParam, default_view)
    })
    let {view_type} = query;
    return (
        <>
            <ViewTypeDropdown custom_options={view_type_options}/>
            {view_type === STATE_PARAM && <UsStateDropdown/>}
            <DemoMenu/>
        </>
    );
}
