import { useQueryParams } from "use-query-params";

export default function ChartError({ error, buttonText, height, width }) {
  let setQuery = useQueryParams({})[1];
  return (
    <div
      className={
        "d-flex align-items-center justify-content-center w-100 position-relative text-gray-600"
      }
      style={{
        paddingTop: (height / width) * 100 + "%",
      }}
    >
      <div
        className="position-absolute text-center"
        style={{ top: "50%", marginTop: "-5em" }}
      >
        <span className="fa fa-warning font-size-30 mb-2 text-gray-600"></span>
        <div>{error}</div>
        <div>
          <button
            className="btn btn-dark mt-4"
            onClick={() => {
              setQuery({}, "push");
            }}
          >
            {buttonText || "Start Over"}
          </button>
        </div>
      </div>
    </div>
  );
}
