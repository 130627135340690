import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className={"mb-3 small text-center mt-auto px-3 w-100"}>
      <small>
        {" "}
        TargetSmart makes no warranty, expressed or implied, as to the results
        obtained from the use of the information on the TargetEarly website.{" "}
        <br />
        <Link to="/cookies">Cookie Policy</Link>
      </small>
    </footer>
  );
}
