import * as d3 from "d3";
import {
  TS_MODELED_PARTIES,
  TS_MODELED_PARTIES_COLORS,
  TS_MODELED_PARTIES_MAP,
} from "./config";
import {
  drawTargetSmartLogo,
  getColorScale,
  getTsHeaderLead,
  width,
  height,
} from "./graphHelpers";

var margin = { top: 20, right: 20, bottom: 70, left: 50, group: 30 };
var legend_width = 110;
var legend_height = 30;

let stroke_width = 1.5;
var ts_graph_width = width - margin.right - margin.left - legend_width;

var party_color_scale = d3
  .scaleOrdinal()
  .domain(TS_MODELED_PARTIES)
  .range(TS_MODELED_PARTIES_COLORS);

// // ====== TIME SERIES DRAW METHODS ====
export function setXYDomains(data, [ts_x_scale, ts_y_scale]) {
  var max_y = d3.max(data, function (d) {
    return d3.max(Object.values(d.value));
  });
  ts_y_scale.domain([max_y * 1.1, 0]);

  let domain_start = data[0]?.key;
  let domain_end = data[data.length - 1]?.key;
  if (!domain_start instanceof Date) {
    domain_start = parseInt(domain_start);
    domain_end = parseInt(domain_end);
  }
  ts_x_scale.domain([domain_start, domain_end]);
  return [ts_x_scale, ts_y_scale];
}

export function createGridlines(svg, ticks, ts_y_scale) {
  // Add gridlines
  let gridlines = d3
    .axisLeft()
    .tickFormat("")
    .ticks(ticks)
    .tickSize(-ts_graph_width)
    .scale(ts_y_scale);

  svg
    .append("g")
    .attr("class", "axis")
    .call(gridlines)
    .call(function (g) {
      g.selectAll(".tick")
        .selectAll("line")
        .attr("class", "dashed stroke-gray-500");
      return g.selectAll(".domain").remove();
    });

  return gridlines;
}

export function createLegend(svg, color_scale) {
  let legend = svg
    .selectAll(".legend")
    .data(color_scale.domain().reverse())
    .enter()
    .append("g");

  legend
    .append("rect")
    .attr("class", "legend")
    .attr("width", 20)
    .attr("height", 20)
    .attr("y", function (d, i) {
      legend_height = color_scale.domain().length * 25;
      let temp = height / 2 - legend_height / 2;
      return temp - i * 25;
    })
    .attr("x", width - legend_width - margin.right)
    .style("fill", function (d) {
      return color_scale(d);
    });

  legend
    .append("text")
    .attr("dy", "1em")
    .attr("y", function (d, i) {
      legend_height = color_scale.domain().length * 25;
      let temp = height / 2 - legend_height / 2;
      return temp - i * 25;
    })
    .attr("x", width - legend_width - margin.right + 25)
    .style("fill", "black")
    .attr("z-index", 1000)
    .text(function (d) {
      return "- " + d;
    });
}

export function createLine(countName) {
  return d3
    .line()
    .x(function (d) {
      return d.coordinates[countName].x;
    })
    .y(function (d) {
      return d.coordinates[countName].y;
    });
}

export function drawLine(
  svg,
  data,
  line,
  color_scale,
  countName,
  color_item,
  setToolTip
) {
  let l = svg
    .append("path")
    .datum(data)
    .style("fill", "none")
    .style("stroke", color_scale(color_item))
    .attr("class", "line")
    .attr("class", "ts_line")
    .attr("stroke-width", stroke_width)
    .attr("stroke-linecap", "round")
    .attr("d", line);

  lineHover(svg, l, countName, color_item, setToolTip);
}

function lineHover(svg, l, countName, color_item, setToolTip) {
  let pathHoverArea = svg
    .append("path")
    .attr("d", l.attr("d"))
    .attr("fill", "none")
    .attr("stroke", "transparent")
    .attr("stroke-width", "40");

  pathHoverArea.attr("display", "none");

  let onHover = (e) => {
    const pointer = d3.pointer(e, this);
    l.attr("stroke-width", "3");
    l.attr("filter", "url(#dropshadow)");
    let hoverData = findNearestDataPoint(l.data()[0], pointer, countName);
    l.raise();
    pathHoverArea.raise();
    marker.attr(
      "transform",
      `translate(${hoverData.coordinates[countName].x},${hoverData.coordinates[countName].y})`
    );
    marker.raise();
    // marker.select("marker").marker(color_item);
    marker.attr("display", "block");
    pathHoverArea.attr("display", "");

    let bb = marker.node().getBoundingClientRect();

    setToolTip({
      show: true,
      key: hoverData.key,
      value: hoverData.value[countName],
      top: bb.top,
      left: bb.left,
      color_item,
      countName,
    });
  };

  var defs = svg.append("defs");

  var filter = defs.append("filter").attr("id", "dropshadow");

  filter
    .append("feDropShadow")
    // .attr("in", "SourceAlpha")
    .attr("dx", 0)
    .attr("dy", 1)
    .attr("result", "offsetBlur")
    .attr("flood-color", "#000")
    .attr("flood-opacity", 0.3)
    .attr("stdDeviation", 3);

  // filter
  //   .append("feGaussianBlur")
  //   .attr("in", "colorOut")
  //   .attr("stdDeviation", 4)
  //   .attr("result", "blur");
  // filter
  //   .append("feOffset")
  //   .attr("in", "blur")
  //   .attr("dx", 2)
  //   .attr("dy", 2)
  //   .attr("result", "offsetBlur");

  var feMerge = filter.append("feMerge");

  feMerge.append("feMergeNode").attr("in", "offsetBlur");
  feMerge.append("feMergeNode").attr("in", "SourceGraphic");

  const marker = svg.append("g");
  marker
    .append("circle")
    .attr("fill", "#fff")
    .attr("stroke", "#999")
    .attr("stroke-width", ".3")
    .attr("r", 4)
    .attr("class", "pointer-events-none");
  marker.attr("display", "none");

  pathHoverArea.on("mouseover mousemove", onHover);
  l.on("mouseover mousemove", onHover);

  pathHoverArea.on("mouseout", function () {
    l.attr("stroke-width", "1.5");
    setToolTip({ show: false });
    marker.attr("display", "none");
    pathHoverArea.attr("display", "none");
    l.attr("filter", "");
  });
}

function findNearestDataPoint(data, mousePosition, countName) {
  let nearestDataPoint;
  let currentNearestDistance = Infinity;
  for (let index = 0; index < data.length; index++) {
    let dataPointDist = Math.abs(
      data[index].coordinates[countName].x - mousePosition[0]
    );
    if (currentNearestDistance > dataPointDist) {
      nearestDataPoint = data[index];
      currentNearestDistance = dataPointDist;
    }
  }
  return nearestDataPoint;
}

export function drawXYAxis(svg, data, ticks, [ts_x_scale, ts_y_scale]) {
  let tickSplit = data.length < 10 ? 1 : Math.round(data.length / 12);

  let dateFormat = d3.timeFormat("%m/%d");
  let tickValues = data
    .map((d) => d.key)
    .filter((tick, i) => {
      return i % tickSplit === 0;
    });

  let x_axis = d3
    .axisBottom()
    .scale(ts_x_scale)
    .tickValues(tickValues)
    .tickFormat((d, i) => {
      if (d instanceof Date) return dateFormat(d);
      else return d;
    });

  svg
    .append("g")
    .attr("transform", "translate(0, " + (height - margin.bottom + 5) + ")")
    .attr("class", "axis")
    .call(x_axis)
    .call(function (g) {
      return g.select(".domain").remove();
    });

  // Add y Axis
  let y_axis = d3
    .axisLeft()
    .scale(ts_y_scale)
    .ticks(ticks)
    .tickFormat(d3.format(".2s"));

  svg
    .append("g")
    .attr("class", "axis")
    .call(y_axis)
    .call(function (g) {
      return g.select(".domain").remove();
    });
}

export function drawXYLabels(svg, x_label, lead) {
  //Add X and Y Labels
  svg
    .append("text")
    .attr("fill", "#6c757d")
    .attr("y", height - 35)
    .attr("x", width / 2 - 100)
    .attr("dy", ".5em")
    .attr("class", "font-size-md-10 font-size-lg-6 font-weight-normal")
    .style("text-anchor", "middle")
    .text(x_label);

  svg
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 0 - margin.left + 3)
    .attr("x", 0 - height / 2 + 13)
    .attr("dy", ".5em")
    .attr("fill", "#6c757d")
    .attr("class", "font-size-md-10 font-size-lg-6 font-weight-normal")
    .style("text-anchor", "middle")
    .text(lead + ": # of ballots cast");
}

export function getYAxisRange(calculated_data) {
  var y_scale = d3
    .scaleLinear()
    .domain([1, 0])
    .range([0, height - margin.bottom]);

  let temp_domain = d3.max(
    calculated_data.map(function (d) {
      return d.value;
    })
  );
  temp_domain = Math.ceil(temp_domain / 0.05) * 0.05;
  temp_domain = d3.min([temp_domain + 0.1, 1]);
  y_scale.domain([temp_domain, 0]);
  return y_scale;
}

export function drawYearlyComparisonTS(
  svg,
  data,
  view_type,
  state,
  years,
  x_label,
  setToolTip,
  year_colors
) {
  var color_scale = getColorScale(years, year_colors);
  svg = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

  let [ts_x_scale, ts_y_scale] = setXYDomains(data, [
    d3.scaleTime().range([0, ts_graph_width]),
    d3
      .scaleLinear()
      .domain([1, 0])
      .range([0, height - margin.bottom]),
  ]);

  let yearly_ticks = 10;
  createGridlines(svg, yearly_ticks, ts_y_scale);
  createLegend(svg, color_scale);

  for (let index = 0; index < data.length; index++) {
    data[index].coordinates = {};
  }

  years.forEach((y, i) => {
    let countName = "voters_" + i;
    for (let index = 0; index < data.length; index++) {
      data[index].coordinates[countName] = {
        x: ts_x_scale(data[index].key),
        y: ts_y_scale(data[index].value[countName]),
      };
    }
    let line = createLine(countName);
    drawLine(svg, data, line, color_scale, countName, y, setToolTip);
  });

  drawXYAxis(svg, data, yearly_ticks, [ts_x_scale, ts_y_scale]);
  drawTargetSmartLogo(svg);

  let lead = getTsHeaderLead(view_type, state, true);
  drawXYLabels(svg, x_label, lead);
}

export function drawModeledPartyRollupTS(
  svg,
  data,
  view_type,
  state,
  x_label,
  setToolTip
) {
  svg = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

  let [ts_x_scale, ts_y_scale] = setXYDomains(data, [
    d3.scaleTime().range([0, ts_graph_width]),
    d3
      .scaleLinear()
      .domain([1, 0])
      .range([0, height - margin.bottom]),
  ]);
  let ts_ticks = 13;
  createGridlines(svg, ts_ticks, ts_y_scale);
  createLegend(svg, party_color_scale);

  for (let index = 0; index < data.length; index++) {
    data[index].coordinates = {};
  }

  let voter_types = Object.keys(TS_MODELED_PARTIES_MAP);
  for (let i = 0; i < voter_types.length; i++) {
    let countName = voter_types[i];
    for (let index = 0; index < data.length; index++) {
      data[index].coordinates[countName] = {
        x: ts_x_scale(data[index].key),
        y: ts_y_scale(data[index].value[countName]),
      };
    }

    let line = createLine(countName);
    drawLine(
      svg,
      data,
      line,
      party_color_scale,
      countName,
      TS_MODELED_PARTIES_MAP[countName].label,
      setToolTip
    );
  }

  drawXYAxis(svg, data, ts_ticks, [ts_x_scale, ts_y_scale]);
  drawTargetSmartLogo(svg);
  let lead = getTsHeaderLead(view_type, state, true);

  drawXYLabels(svg, x_label, lead);
}
