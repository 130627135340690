import { useRef, useEffect, useState, useContext } from "react";
import * as d3 from "d3";

import { ToolTip } from "../toolTip";
import { numberText } from "../helpers/helpers";
import { ConfigContext } from "../ConfigContext";
import { drawYearlyComparisonTS } from "../helpers/timeSeriesHelpers";
import ChartError from "./ChartError";
import TimeSeriesTable from "./timeSeriesTable";

export function YearlyComparison({
  height,
  width,
  data,
  years,
  view_type,
  state,
  error,
  title,
}) {
  const svgRef = useRef(null);
  let [toolTipData, setToolTipData] = useState({
    show: false,
    top: 0,
    left: 0,
  });
  let config = useContext(ConfigContext);

  let isDateType = data.length > 1 && data[0].key instanceof Date;
  let x_label = isDateType ? "Date of Votes Cast" : "Days out from election";

  if (toolTipData.key instanceof Date) {
    const formatTime = d3.timeFormat("%m/%d");
    toolTipData.key = formatTime(toolTipData.key);
  }
  const setToolTip = (newData) => {
    let tooltip = Object.assign(toolTipData, newData);
    setToolTipData({ ...tooltip });
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    drawYearlyComparisonTS(
      svg,
      data,
      view_type,
      state,
      years,
      x_label,
      setToolTip,
      config.YEAR_COLORS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <svg
        ref={svgRef}
        viewBox={"0 0 " + width + " " + height}
        style={{ display: !error ? "inherit" : "none" }}
        aria-hidden={true}
      />
      {!error && (
        <TimeSeriesTable
          data={data}
          dataKeys={years.map((y, i) => {
            return "voters_" + i;
          })}
          headers={[x_label, ...years]}
          title={title}
        />
      )}
      {!!error && (
        <ChartError
          error={error}
          buttonText={"Try again"}
          height={height}
          width={width}
        />
      )}
      <ToolTip
        show={toolTipData.show}
        left={toolTipData.left - 98}
        top={toolTipData.top + 35}
        width={210}
      >
        {toolTipData.key && toolTipData.countName && (
          <div className=" d-flex flex-column mb-0">
            <div className="d-flex align-items-center bg-gray-100 p-2 rounded-top border-bottom">
              {toolTipData.countName && (
                <div
                  className="mr-1"
                  style={{
                    height: "1em",
                    width: "1em",
                    backgroundColor:
                      config.YEAR_COLORS[
                        toolTipData.countName.replace("voters_", "")
                      ],
                  }}
                ></div>
              )}
              {toolTipData.color_item}
            </div>
            <div className="pt-2 p-2 bg-white rounded-bottom">
              <div>
                {x_label}:{" "}
                <span className="font-weight-bold">{toolTipData.key}</span>
              </div>{" "}
              <div>
                Vote Count:{" "}
                <span className="font-weight-bold">
                  {numberText(toolTipData.value)}
                </span>
              </div>
            </div>
          </div>
        )}
      </ToolTip>
    </>
  );
}
