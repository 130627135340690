import { useEffect, useState } from "react";

export default function SrNavigationAlert({ children }) {
  let [message, setMessage] = useState();
  useEffect(() => {
    let setMess = setTimeout(() => {
      setMessage(children);
    }, 50);
    let clearMess = setTimeout(() => {
      setMessage();
    }, 500);
    return () => {
      clearTimeout(setMess);
      clearTimeout(clearMess);
    };
  }, [children]);
  return (
    <>
      {message && (
        <div className="sr-only" role="alert" aria-relevant="additions">
          {message}
        </div>
      )}
    </>
  );
}
