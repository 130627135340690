export default function ProgressBar({ percent, label, aspectRatio }) {
  return (
    <div
      className={
        "d-flex align-items-center justify-content-center w-100 position-relative text-gray-600 "
      }
      style={{
        paddingTop: aspectRatio * 100 + "%",
      }}
    >
      <div
        style={{
          top: "50%",
          marginTop: "-.5em",
          height: "1em",
        }}
        className="bg-gray-200 w-80 position-absolute"
      >
        <div
          className="h-100 bg-gray-600"
          style={{
            width: percent * 100 + "%",
          }}
        ></div>
      </div>
      <span
        style={{ top: "50%", marginTop: "-3em" }}
        className="position-absolute small"
      >
        {Math.round(percent * 100)}%
      </span>
      <span
        style={{ top: "50%", marginTop: "2em" }}
        className="position-absolute small"
      >
        {label ? label : "Loading..."}
      </span>
    </div>
  );
}
