import { ConfigContext } from "../ConfigContext";
import { useContext } from "react";
import RadioButton from "./radioButton";

export default function CompareVoteCountButtons({
  countPrefix,
  setCountPrefix,
}) {
  let config = useContext(ConfigContext);
  let edNotes = config.editorialNotes;
  let daysOut = !!edNotes ? edNotes[0].days_to_election : "";
  return (
    <div className="d-flex flex-column mr-3 mb-3 text-nowrap">
      <div>
        <label>
          Compare {config.YEAR_LABELS[config.YEAR_LABELS.length - 1]} early
          votes to: &nbsp;
        </label>
      </div>
      <div className="d-flex flex-column small">
        {config.isCurrent && (
          <RadioButton
            buttonLabel={`${daysOut} days to election`}
            buttonValue={"current_eav"}
            buttonPrefix={config.COUNT_PREFIXES.CURRENT_EAV_COUNT_PREFIX}
            countPrefix={countPrefix}
            setCountPrefix={setCountPrefix}
          />
        )}
        <RadioButton
          buttonLabel={"Final early/mail"}
          buttonValue={"final_eav"}
          buttonPrefix={config.COUNT_PREFIXES.FINAL_EAV_COUNT_PREFIX}
          countPrefix={countPrefix}
          setCountPrefix={setCountPrefix}
        />
        <RadioButton
          buttonLabel={"Final election"}
          buttonValue={"final_eday"}
          buttonPrefix={config.COUNT_PREFIXES.FINAL_EDAY_COUNT_PREFIX}
          countPrefix={countPrefix}
          setCountPrefix={setCountPrefix}
        />
      </div>
    </div>
  );
}
