import BaseDropdown from "./baseDropdown";
import { StringParam, useQueryParam } from "use-query-params";
import { GEO_TYPE_PARAM, GEO_VALUE_PARAM } from "./urlParams";
import { useContext, useMemo } from "react";
import { ConfigContext } from "../ConfigContext";
import { convertItemsToOptions } from "../helpers/helpers";

export default function GeoValueDropdown() {
  const [geo_type] = useQueryParam(GEO_TYPE_PARAM, StringParam);
  const config = useContext(ConfigContext);
  const geoTypeLabel = config.GEO_TYPE_OPTIONS.find((item) => {
    return item.value === geo_type;
  })?.label;

  const getGeoValueOptions = (geo_type) => {
    let stateGeoTypeOptions = [
      ...new Set(
        config.VIEW_DATA?.map(
          (item) => item[config.GEO_TYPE_OPTIONS_MAPPING[geo_type]]
        )
      ),
    ];
    return convertItemsToOptions(stateGeoTypeOptions);
  };

  let memoGeoValOptions = useMemo(() => {
    return getGeoValueOptions(geo_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo_type, config.VIEW_DATA]);

  return (
    <>
      {memoGeoValOptions?.length && (
        <BaseDropdown
          items={memoGeoValOptions}
          label={geoTypeLabel}
          paramName={GEO_VALUE_PARAM}
          paramType={StringParam}
        />
      )}
    </>
  );
}
